import YoutubeProvider from "../providers/YoutubeProvider"

export default class YoutubeHelper {
  player: YT.Player | null = null
  playerState = window?.YT?.PlayerState?.UNSTARTED
  meta = {
    title: "",
    artist: "",
  }

  constructor() {
    window.onYouTubeIframeAPIReady = () => {
      this.player = this.createPlayer()
    }
  }

  createPlayer() {
    return new window.YT.Player("youtube-player-dom-id", {
      events: {
        onStateChange: (e) => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          this.meta.title = e.target.playerInfo.videoData.title
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          this.meta.artist = e.target.playerInfo.videoData.author
          this.player = e.target
          this.playerState = e.target.getPlayerState()
        },
      },
      playerVars: {
        origin: `${window.location.protocol}//${window.location.hostname}`,
        autoplay: window.YT?.AutoPlay?.NoAutoPlay,
      },
    })
  }

  getAccessToken() {
    return "youtube:access_token"
  }

  getInstance(): YT.Player {
    if (!this.player) {
      this.player = this.createPlayer()
    }
    return this.player
  }

  async getProvider() {
    try {
      const instance = await this.getInstance()
      return new YoutubeProvider(instance)
    } catch (error) {}
  }

  authorize() {
    return Promise.resolve(true)
  }

  async isAuthorized() {
    return true
  }
}
