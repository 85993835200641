/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import Cookies from "js-cookie"
import React from "react"
import Services from "../helpers/Services"
import Provider, { SongInfo, Status } from "../providers/Provider"
import { Song } from "../types/get-songs"

export type ServicesKeys = keyof Services["services"]

type State = {
  playerProvider: null | Provider
  playerStatus: typeof Status[keyof typeof Status]
  currentSong: null | SongInfo
  songProgress: string
  showSelector: boolean
  selectorCallback: () => Promise<void>
}
const defaultState = {
  // false if none, spotify or apple
  playerProvider: null,
  playerStatus: Status.STOPPED,
  currentSong: null,
  songProgress: "00:00",
  showSelector: false,
  selectorCallback: () => Promise.resolve(),
} as const

const defaultContextState = {
  state: defaultState,
  services: null,
  setProvider: (_provider: Provider) => {},
  playSong: (_song: Song) => {},
  playPlaylist: (_playlist: any) => {},
  canPlayPlaylist: (_playlist: { dsp: any }) => {},
  authorize: () => Promise.resolve(),
  getService: () => {},
  selectProvider: (_providerName: string) => Promise.resolve(),
  closeSelector: () => {},
} as const
type DefaultContextState = {
  state: State
  services: null | Services
  setProvider: (provider: Provider) => void
  playSong: (song: Song) => void
  playPlaylist: (playlist: any) => void
  canPlayPlaylist: (playlist: { dsp: any }) => void
  authorize: (callback: () => void, providerName: ServicesKeys) => Promise<unknown>
  getService: () => void
  selectProvider: (providerName: ServicesKeys) => Promise<unknown>
  closeSelector: () => void
}

export const MusicPlayerContext = React.createContext<DefaultContextState>(defaultContextState)

export default class MusicPlayerContextProvider extends React.Component {
  override state: State = defaultState

  services: Services | null = null

  constructor(props: any) {
    super(props)

    this.services = new Services()

    this.authorize = this.authorize.bind(this)
    this.getService = this.getService.bind(this)

    this.selectProvider = this.selectProvider.bind(this)
    this.closeSelector = this.closeSelector.bind(this)
    this.playSong = this.playSong.bind(this)
    this.playPlaylist = this.playPlaylist.bind(this)
  }

  override componentDidMount() {
    // @ts-ignore
    this.setProvider(this.services?.getPreAuthenticatedProvider())
  }

  closeSelector() {
    this.setState({
      showSelector: false,
      selectorCallback: () => {},
    })
  }

  async setProvider(provider: Provider | Promise<Provider | undefined>) {
    if (this.state.playerProvider) {
      // @ts-ignore
      this.state.playerProvider?.dispose()
      this.setState({
        playerStatus: Status.STOPPED,
      })
    }

    let providerInstance: Provider | undefined

    if (provider instanceof Promise) {
      try {
        providerInstance = await provider
      } catch (e) {
        console.error("error getting provider", provider)
        // @ts-ignore
        providerInstance = null
      }
    } else {
      providerInstance = provider
    }

    if (providerInstance) {
      providerInstance.info().then((songInfo) => {
        this.setState({
          currentSong: songInfo,
        })
      })

      providerInstance.onSongChange((songInfo) => {
        this.setState({
          currentSong: songInfo,
        })
      })

      providerInstance.onPlayerStatusChange((status) => {
        this.setState({
          playerStatus: status,
        })
      })

      providerInstance.onSongProgressChange((progress) => {
        this.setState({
          songProgress: progress,
        })
      })

      // @ts-ignore
      providerInstance.onError((error: Error) => {
        console.log("onError error: ", error)
        if (error?.message === "Authentication failed") {
          providerInstance?.dispose()

          this.setState({
            playerProvider: null,
          })
        }
      })
    }

    this.setState({
      playerProvider: providerInstance,
    })
  }

  authorize(
    callback: (playerProvider: Provider | null) => void,
    providerName: keyof Services["services"],
  ): Promise<unknown> {
    return new Promise((resolve) => {
      const useExistingProvider =
        !providerName &&
        this.state.playerProvider &&
        (this.services?.services[
          this.state.playerProvider.providerName as ServicesKeys
        ]?.isAuthorized() ||
          false)

      if (useExistingProvider) {
        console.log("Provider detected: " + this.state.playerProvider?.providerName)
        callback(this.state.playerProvider)
        resolve(this.state.playerProvider)
        return
      }

      let currentProviderName = providerName
      const lastProvider = Cookies.get("lastProvider") as ServicesKeys
      if (!currentProviderName && lastProvider) {
        currentProviderName = lastProvider
      }

      if (currentProviderName) {
        const service = this.services?.services[currentProviderName]

        if (service) {
          service
            .authorize()
            ?.then(() => {
              const newProvider = service.getProvider()
              this.setProvider(newProvider).then(() => {
                console.log("Authorized provider", this.state.playerProvider, callback)
                callback(this.state.playerProvider)
                resolve(this.state.playerProvider)
              })
            })
            .catch((error: Error) => {
              console.trace()
              console.error(error)
            })

          return
        }
      }

      this.setState({
        showSelector: true,
        // @ts-ignore
        selectorCallback: (selectedProvider) => {
          callback(selectedProvider)
          this.closeSelector()
        },
      })
    })
  }

  getService() {
    return this.services?.services[this.state.playerProvider?.providerName as ServicesKeys]
  }

  selectProvider(providerName: ServicesKeys) {
    return this.authorize(this.state.selectorCallback, providerName)
  }

  playSong(song: Song, tries = 0) {
    // @ts-ignore
    this.authorize((provider) => {
      if (!provider) return console.error("this.authorize -   Provider not set!")
      provider
        .setSong(song)
        .then(() => {
          provider.play()
        })
        .catch(() => {
          // @ts-ignore
          if (!this.services[provider.providerName]?.isAuthorized() && tries < 3) {
            console.log("Retrying to play", { providerName: provider.providerName, tries, song })
            this.playSong(song, tries + 1)
          }

          alert("Could not play song. Please refresh and try again.") // eslint-disable-line no-alert
        })
    })
  }

  // @ts-ignore
  playPlaylist(playlist, tries = 0) {
    // @ts-ignore
    this.authorize((provider) => {
      if (!provider) return console.error("this.authorize - Provider not set!")
      provider
        .setPlaylist(playlist)
        .then(() => {
          provider.play()
        })
        .catch(() => {
          // @ts-ignore
          if (!this.services[provider.providerName]?.isAuthorized() && tries < 3) {
            console.log("Retrying to play playlist", {
              providerName: provider.providerName,
              tries,
              playlist,
            })
            this.playPlaylist(playlist, tries + 1)
          }

          alert("Could not play playlist. Please refresh and try again.") // eslint-disable-line no-alert
        })
    })
  }

  override render() {
    return (
      // @ts-ignore
      <MusicPlayerContext.Provider
        value={{
          state: this.state,
          services: this.services,
          setProvider: (provider: Provider) => this.setProvider(provider),
          playSong: (song: Song) => this.playSong(song, 0),
          playPlaylist: (playlist: any) => this.playPlaylist(playlist, 0),
          canPlayPlaylist: (playlist: { dsp: any }) => {
            // @ts-ignore
            if (!this.state.playerProvider && !this.state.lastProvider) {
              return true
            }

            if (this.state.playerProvider) {
              // @ts-ignore
              return this.state.playerProvider.providerName === playlist.dsp
            }

            // @ts-ignore
            if (this.state.lastProvider) {
              // @ts-ignore
              return this.state.lastProvider === playlist.dsp
            }

            return false
          },
          authorize: this.authorize,
          getService: this.getService,
          selectProvider: this.selectProvider,
          closeSelector: this.closeSelector,
        }}
      >
        {this.props.children}
      </MusicPlayerContext.Provider>
    )
  }
}

export const MusicPlayerContextConsumer = MusicPlayerContext.Consumer
