import React, { Component } from "react"
import AddToList from "../../svgs/AddToList"
import DeleteIcon from "../../svgs/DeleteIcon"
import { ManagePlaylistsContext } from "../../contexts/ManagePlaylistsContext"

class AddToPlayListSelect extends Component {
  constructor(props) {
    super(props)

    this.handleChange = this.handleChange.bind(this)
  }

  handleChange(e) {
    if (e.target.selectedIndex === 0) {
      this.props.selectPlaylist(null)
      return
    }

    const playlist = this.props.playlists[parseInt(e.target.selectedIndex) - 1]
    this.props.selectPlaylist(playlist)
  }

  render() {
    return (
      <div className="entry-list">
        <label>
          {this.props.loading && (
            <>
              <span>LOADING </span> PLAYLISTS
            </>
          )}
          {!this.props.loading && !this.props.selectedPlaylist && (
            <>
              <span>ADD TO </span> PREVIOUS PLAYLIST
            </>
          )}
          {this.props.selectedPlaylist && <>{this.props.selectedPlaylist.name}</>}
        </label>
        {!this.props.loading && (
          <select
            name=""
            onChange={this.handleChange}
            value={this.props.selectedPlaylist ? this.props.selectedPlaylist.id : ""}
          >
            <option value="">Add to Previous Playlist</option>
            {this.props.playlists.map((playlist) => (
              <option value={playlist.id} key={playlist.id}>
                {playlist.name}
              </option>
            ))}
          </select>
        )}
      </div>
    )
  }
}

class AddToPlayListModal extends Component {
  constructor(props) {
    super(props)

    this.state = {
      createPlaylist: false,
      creatingPlaylist: false,

      addingSongsToPlaylist: false,
      selectedPlaylist: null,

      playlistTitle: "",
    }

    this.close = this.close.bind(this)
    this.selectPlaylist = this.selectPlaylist.bind(this)
    this.changePlaylistTitle = this.changePlaylistTitle.bind(this)
    this.createPlaylist = this.createPlaylist.bind(this)
    this.addSongsToPlaylist = this.addSongsToPlaylist.bind(this)
  }

  selectPlaylist(playlist) {
    this.setState({
      createPlaylist: false,
      selectedPlaylist: playlist,
    })
  }

  close() {
    this.setState({
      creatingPlaylist: false,
      createPlaylist: false,
      selectedPlaylist: null,
      addingSongsToPlaylist: false,
      playlistTitle: "",
    })

    this.context.actions.toggleModal()
  }

  changePlaylistTitle(e) {
    this.setState({
      playlistTitle: e.target.value,
    })
  }

  createPlaylist() {
    this.setState({
      creatingPlaylist: true,
    })

    this.context.actions
      .addPlaylist(this.state.playlistTitle)
      .then(() => {
        this.setState({
          creatingPlaylist: false,
          createPlaylist: false,
          selectedPlaylist: null,
        })

        this.close()
      })
      .catch(() => {
        this.setState({
          creatingPlaylist: false,
        })
      })
  }

  addSongsToPlaylist() {
    this.setState({
      addingSongsToPlaylist: true,
    })

    this.context.actions
      .addSongsToPlaylist(this.state.selectedPlaylist.id)
      .then(() => {
        this.setState({
          addingSongsToPlaylist: false,
          selectedPlaylist: null,
        })

        this.close()
      })
      .catch(() => {
        this.setState({
          addingSongsToPlaylist: false,
        })
      })
  }

  render() {
    if (!this.context.state.visible) {
      return null
    }

    const visiblePlaylists = this.context.state.playlists.filter(
      (playlist) => playlist.dsp === this.context.state.currentDsp,
    )
    const createPlaylist =
      this.state.createPlaylist ||
      (this.context.state.playlistsLoaded && visiblePlaylists.length === 0)

    return (
      <div className="_modal">
        <button className="close" onClick={this.close}>
          <DeleteIcon fill="#fff" />
        </button>
        <div className="_modal-wrap">
          <div className="_modal-body">
            {!this.state.selectedPlaylist && createPlaylist === false && (
              <div>
                <button
                  className="entry-link"
                  onClick={() => {
                    this.setState({
                      createPlaylist: true,
                    })
                  }}
                >
                  <span>+ CREATE</span> NEW PLAYLIST
                </button>
                <AddToPlayListSelect
                  loading={this.context.state.playlistsLoading}
                  playlists={visiblePlaylists}
                  selectedPlaylist={this.state.selectedPlaylist}
                  selectPlaylist={this.selectPlaylist}
                />
              </div>
            )}

            {createPlaylist === true && (
              <div>
                <div className="entry-link">
                  <span>CREATE</span> NEW PLAYLIST
                </div>

                <div className="addNewPlayList">
                  <div className="_frmg">
                    <input
                      placeholder="PLAYLIST TITLE"
                      type="text"
                      onChange={this.changePlaylistTitle}
                      name="playlisttitle"
                      value={this.state.playlistTitle}
                      readOnly={this.state.creatingPlaylist}
                    />
                  </div>

                  <div>
                    <button
                      className="button full"
                      onClick={this.createPlaylist}
                      disabled={
                        this.state.playlistTitle.length === 0 || this.state.creatingPlaylist
                      }
                    >
                      <AddToList fill="#DEB421" /> ADD TO PLAYLIST
                    </button>
                  </div>
                  {visiblePlaylists.length > 0 && (
                    <div className="goBack">
                      <button
                        onClick={() => {
                          this.setState({
                            createPlaylist: false,
                            selectedPlaylist: null,
                            playlistTitle: "",
                          })
                        }}
                      >
                        {"<"} BACK TO SELECTION
                      </button>
                    </div>
                  )}
                </div>
              </div>
            )}

            {this.state.selectedPlaylist && (
              <div>
                <div className="entry-link">
                  <span>Add To</span> Previous playlist
                </div>
                <div className="entry-list selected">
                  <label>
                    {this.state.selectedPlaylist.name}
                    <button
                      onClick={this.addSongsToPlaylist}
                      className="leftBtn"
                      disabled={this.state.addingSongsToPlaylist}
                    >
                      &raquo;
                    </button>
                  </label>
                  <div className="goBack">
                    <button
                      onClick={() => {
                        this.setState({
                          createPlaylist: false,
                          selectedPlaylist: null,
                          playlistTitle: "",
                        })
                      }}
                    >
                      {"<"} BACK TO SELECTION
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    )
  }
}

AddToPlayListModal.contextType = ManagePlaylistsContext

export default AddToPlayListModal
