import React, { Component } from "react"

export default class Vector extends Component {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="22"
        height="20"
        viewBox="0 0 22 20"
        fill="none"
      >
        <path
          d="M20 3.18L8.59 14.6L4.35 10.36L5.76 8.95L8.59 11.78L18.59 1.78L20 3.18ZM10 18C5.59 18 2 14.41 2 10C2 5.59 5.59 2 10 2C11.57 2 13.04 2.46 14.28 3.25L15.73 1.8C14.1 0.67 12.13 0 10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C11.73 20 13.36 19.56 14.78 18.78L13.28 17.28C12.28 17.74 11.17 18 10 18ZM17 13H14V15H17V18H19V15H22V13H19V10H17V13Z"
          fill="#919191"
        />
      </svg>
    )
  }
}
