import React from "react"
import { BrowserRouter as Router, Redirect, Route, Switch } from "react-router-dom"
import "./assets/sass/style.scss"
import FeaturedPlaylist from "./components/FeaturedPlaylists/FeaturedPlayList"
import Home from "./components/Home/Home"
import Footer from "./components/Layout/Footer"
import Header from "./components/Layout/Header"
import Login from "./components/Login/Login"
import MyPlayList from "./components/MyPlaylists/MyPlayList"
import MyPlayLists from "./components/MyPlaylists/MyPlayLists"
import ScrollToTop from "./components/ScrollToTop"
import AddToPlayListModal from "./components/sections/AddToPlayListModal"
import SelectDSPModal from "./components/sections/SelectDSPModal"
import AllShow from "./components/Shows/AllShows"
import MyShows from "./components/Shows/MyShows"
import SingleShow from "./components/Shows/SingleShow"
import SingleSong from "./components/Songs/SingleSong"
import { AppConsumer, AppProvider, useAppContext } from "./contexts/AppContext"
import { ManagePlaylistsProvider } from "./contexts/ManagePlaylistsContext"
import MusicPlayerContextProvider from "./contexts/MusicPlayerContext"
import Services from "./helpers/Services"
import Loading from "./Loading"
import NotFound from "./NotFound"

window.mapboxgl.accessToken =
  "pk.eyJ1IjoicGVhcmxqYW0iLCJhIjoiY2pzYnYyeXRqMGZmeDRhbG5lMHZpbWh4cSJ9.bmHK43wEOOyqmKhzuwCQSA"

class App extends React.PureComponent {
  services: Services
  constructor(props: any) {
    super(props)

    this.services = new Services()
  }

  override render() {
    return (
      <div className="body">
        <Router>
          <AppProvider authed={false}>
            <MusicPlayerContextProvider>
              <ManagePlaylistsProvider>
                <Header />
                <ScrollToTop />
                <AppConsumer>
                  {(context) => (
                    <div className="content">
                      <Switch>
                        {!context.state.isLoaded && <Route component={Loading} />}
                        <Route exact path="/" component={Home} />
                        <Route exact path="/shows" component={AllShow} />
                        <Route exact path="/shows/:id" component={SingleShow} />
                        <Route exact path="/shows/:showId/songs/:songId" component={SingleSong} />
                        <Route exact path="/featured-playlists/:id" component={FeaturedPlaylist} />
                        <Route exact path="/not-found" component={NotFound} />

                        <PrivateRoute exact path="/my-playlists/:id" component={MyPlayList} />
                        <PrivateRoute exact path="/my-playlists" component={MyPlayLists} />
                        <PrivateRoute exact path="/my-shows" component={MyShows} />

                        {!context.state.isAuthenticated && (
                          <Route exact path="/login" component={Login} />
                        )}
                        <Route component={NotFound} />
                      </Switch>
                    </div>
                  )}
                </AppConsumer>

                <AddToPlayListModal />
                <SelectDSPModal />

                <Footer />
              </ManagePlaylistsProvider>
            </MusicPlayerContextProvider>
          </AppProvider>
        </Router>
      </div>
    )
  }
}

export default App

/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-ignore
const PrivateRoute = ({ component: Component, ...rest }) => {
  const appContext = useAppContext()

  return (
    <Route
      {...rest}
      render={(props) =>
        appContext.state.isAuthenticated ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: "/login", state: { from: props.location } }} />
        )
      }
    />
  )
}
