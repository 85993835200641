import React, { Component } from "react"
import CuratedPlaylistGallery from "../sections/CuratedPlaylistGallery"
import SinglePlaylist from "../sections/SinglePlaylist"

import * as Api from "../../utils/Api"

export default class FeaturedPlaylist extends Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: true,
      playlist: null,
    }
  }

  componentDidMount() {
    this.loadPlaylist()
  }

  componentDidUpdate() {
    if (
      !this.state.playlist ||
      parseInt(this.state.playlist.id) !== parseInt(this.props.match.params.id)
    ) {
      this.loadPlaylist()
    }
  }

  loadPlaylist() {
    // this.setState({
    //   loading: true
    // })

    Api.getFeaturedPlaylist(this.props.match.params.id).then(({ data: { data } }) => {
      this.setState({
        loading: false,
        playlist: data.featured_playlist,
      })
    })
  }

  render() {
    return (
      <div>
        <SinglePlaylist
          playlist={this.state.playlist}
          loading={this.state.loading}
          featured={true}
        />
        <CuratedPlaylistGallery />
      </div>
    )
  }
}
