/* eslint-disable no-return-assign */
import React, { Component } from "react"
import SongsSeenIcon from "../../assets/img/Frame.png"
import Background from "../../assets/img/pearl-jam-live.jpg"
import ShowsAttendedIcon from "../../assets/img/showttanded.png"
import AlbumsSeenIcon from "../../assets/img/totalAlbums.png"
import { AppContext } from "../../contexts/AppContext"
import * as Api from "../../utils/Api"



export default class MyShow extends Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: true,
      my_show_stats: {
        shows_attended: "...",
        songs_seen: "...",
        total_albums_seen: 0,
      },
    }

    this.buildMap = this.buildMap.bind(this)
  }

  buildMap(myShows) {
    if (myShows.length < 1) {
      return
    }

    let minLng
    let minLat
    let maxLng
    let maxLat

    myShows.forEach((show_id) => {
      const show = this.context.state.showsById[parseInt(show_id)]

      let el = document.createElement("div")
      el.className = "map-marker"

      if (show.lat_long === "") {
        return
      }

      const latlng = show.lat_long.split(",")
      if (latlng.length < 2) {
        return
      }

      let lat = parseFloat(latlng[0])
      let lng = parseFloat(latlng[1])

      if (lng > maxLng || !maxLng) {
        maxLng = lng
      }

      if (minLng > lng || !minLng) {
        minLng = lng
      }

      if (lat > maxLat || !maxLat) {
        maxLat = lat
      }

      if (minLat > lat || !minLat) {
        minLat = lat
      }

      new window.mapboxgl.Marker(el).setLngLat([latlng[1], latlng[0]]).addTo(this.map)
    })

    if (minLng && minLat && maxLng && maxLat) {
      this.map.fitBounds(
        [
          [minLng, minLat],
          [maxLng, maxLat],
        ],
        {
          padding: 100,
          linear: true,
          maxZoom: 5,
        },
      )
    }
  }

  componentDidMount() {
    this.map = new window.mapboxgl.Map({
      container: this.mapContainer,
      style: "mapbox://styles/pearljam/cjsbv3cnx0a2w1fnyyqx1nuis",
      center: [-96, 37.8],
      zoom: 3,
    })

    this.context.actions.loadMyShows()?.then((myShows) => {
      this.buildMap(myShows)
    })

    Api.getMyShowStats().then(({ data: { data } }) => {
      this.setState({
        loading: false,
        my_show_stats: data.my_show_stats,
      })
    })
  }

  render() {
    return (
      <div className="singleshow" style={{ backgroundImage: "url(" + Background + ")" }}>
        <div className="max-width">
          <div className="wrap">
            <div className="d-flex f-wrap align-center">
              <div className="_clh">
                <div className="map">
                  <div ref={(el) => (this.mapContainer = el)} />
                </div>
              </div>
              <div className="_clh _clh-right">
                <div className="wrap">
                  <h2 className="h2">My Stats</h2>

                  <div className="details  d-flex f-wrap">
                    <div className="item">
                      <div className="num">
                        {this.state.my_show_stats.shows_attended}{" "}
                        <img alt="" src={ShowsAttendedIcon} />
                      </div>
                      <div className="">SHOWS ATTENDED</div>
                    </div>

                    <div className="item">
                      <div className="num">
                        {this.state.my_show_stats.songs_seen} <img alt="" src={SongsSeenIcon} />
                      </div>
                      <div className="">SONGS SEENS</div>
                    </div>

                    {parseInt(this.state.my_show_stats.total_albums_seen) > 0 && (
                      <div className="item">
                        <div className="num">
                          {this.state.my_show_stats.total_albums_seen}{" "}
                          <img alt="" src={AlbumsSeenIcon} />
                        </div>
                        <div className="">TOTAL ALBUMS SEEN</div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

MyShow.contextType = AppContext
