import React, { Component } from "react"
import Banner from "../sections/Banner"
import PlayList from "../sections/PlayList"
import SinglePlaylist from "../sections/SinglePlaylist"

import * as Api from "../../utils/Api"

export default class MyPlayLists extends Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: true,
      playlist: null,
    }
  }

  componentDidMount() {
    this.loadPlaylist()
  }

  componentDidUpdate() {
    if (
      !this.state.playlist ||
      parseInt(this.state.playlist.id) !== parseInt(this.props.match.params.id)
    ) {
      this.loadPlaylist()
    }
  }

  loadPlaylist() {
    // this.setState({
    //   loading: true
    // })

    Api.getMyPlaylist(this.props.match.params.id)
      .then(({ data: { data } }) => {
        this.setState({
          loading: false,
          playlist: data.playlist,
        })
      })
      .catch(() => {
        this.setState({
          loading: false,
        })
      })
  }

  render() {
    return (
      <div>
        <SinglePlaylist
          playlist={this.state.playlist}
          loading={this.state.loading}
          history={this.props.history}
        />
        <PlayList />
        <Banner />
      </div>
    )
  }
}
