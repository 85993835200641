import * as React from "react"
import { Redirect } from "react-router-dom"
import { useAppContext } from "../../contexts/AppContext"
import FanSummary from "../sections/FanSummary"
import Photos from "../sections/Photos"
import SetList from "../sections/SetList"
import ShowDetails from "../sections/ShowDetails"

const SingleShow = (props: { match: { params: { id: number } } }) => {
  const context = useAppContext()

  if (typeof context.state.showsById[props.match.params.id] === "undefined") {
    return <Redirect to="/not-found" />
  }

  const show = context.state.showsById[props.match.params.id]

  return (
    <div>
      <ShowDetails show={show} />
      {show.show_setlist && <SetList show={show} />}
      {show.has_gallery && <Photos show={show} />}

      {show.summary.map((summary, index) => (
        <FanSummary key={summary.id} {...{ index, summary }} />
      ))}
    </div>
  )
}

export default SingleShow
