import Cookies from "js-cookie"
import SpotifyProvider from "../providers/SpotifyProvider"

export default class SpotifyHelper {
  player: Promise<Spotify.Player> | Spotify.Player | null = null

  constructor() {
    this.player = new Promise<Spotify.Player>((resolve) => {
      window.onSpotifyWebPlaybackSDKReady = () => {
        const name = process.env.REACT_APP_SPOTIFY_PLAYER_NAME
        if (typeof name !== "string") {
          return console.error("REACT_APP_SPOTIFY_PLAYER_NAME !== 'string'")
        }
        const player = new window.Spotify.Player({
          name,
          getOAuthToken: (callback) => {
            callback(this.getAccessToken())
          },
        })

        resolve(player)
      }
    })
  }

  getAccessToken() {
    return Cookies.get("spotify-token") ?? ""
  }

  getInstance(): Promise<Spotify.Player> {
    return new Promise((resolve, reject) => {
      if (!(this.player instanceof Promise)) return reject("Not promise!")

      this.player.then(async (player: Spotify.Player) => {
        player.addListener("ready", (playerInfo) => {
          player._options.id = playerInfo.device_id // eslint-disable-line no-underscore-dangle
          resolve(player)
        })

        player.removeListener("account_error")
        player.addListener("account_error", async () => {
          // eslint-disable-next-line no-alert
          alert("You must have a Spotify Premium subscription in order to use this.")
          await player.disconnect()
          Cookies.remove("spotify-token")
        })

        try {
          await player.connect()
        } catch (e) {
          console.error(e)

          reject(e)
        }
      })
    })
  }

  async getProvider() {
    try {
      const instance = await this.getInstance()
      return new SpotifyProvider(instance)
    } catch (error) {
      console.error({ error })
    }
  }

  authorize() {
    return new Promise((resolve) => {
      const popup = window.open(
        process.env.REACT_APP_SITE_URL + "api/deep/login/spotify",
        "spotifyauth",
        "height=600,width=400,resizable=no",
      )
      window.spotifyAuthCB = (token) => {
        Cookies.set("spotify-token", token, {
          expires: 1 / 24,
        })

        popup?.close()
        resolve(true)
      }
    })
  }

  async isAuthorized() {
    if (!Cookies.get("spotify-token")) {
      return false
    }

    const response = await fetch(`https://api.spotify.com/v1/me`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Cookies.get("spotify-token")}`,
      },
    })

    return response.ok
  }

  static playTrackRequest(instance: Spotify.Player, spotifyUri: string) {
    return new Promise((resolve, reject) => {
      const {
        _options: { getOAuthToken, id },
      } = instance

      getOAuthToken((token) => {
        if (!id) {
          reject(`Could not play playlist, device not ready`)
          return
        }

        fetch(`https://api.spotify.com/v1/me/player/play?device_id=${id}`, {
          method: "PUT",
          body: JSON.stringify({ uris: [spotifyUri] }),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
          .then((res) => {
            if (res.status === 204) {
              resolve(true)
              return
            }

            reject(`Could not play track, invalid status code ${res.status}`)
          })
          .catch(reject)
      })
    })
  }

  static playPlaylistRequest(instance: Spotify.Player, spotifyUri: string) {
    return new Promise((resolve, reject) => {
      const {
        _options: { getOAuthToken, id },
      } = instance

      getOAuthToken((token) => {
        if (!id) {
          reject(`Could not play playlist, device not ready`)
          return
        }

        fetch(`https://api.spotify.com/v1/me/player/play?device_id=${id}`, {
          method: "PUT",
          body: JSON.stringify({ context_uri: spotifyUri }),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
          .then((res) => {
            if (res.status === 204) {
              resolve(true)
              return
            }

            reject(`Could not play playlist, invalid status code ${res.status}`)
          })
          .catch(reject)
      })
    })
  }
}
