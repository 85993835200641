import FsLightbox from "fslightbox-react"
import React, { useEffect, useMemo, useState } from "react"
import Skeleton, { SkeletonTheme } from "react-loading-skeleton"
import Slider from "react-slick"
import * as Api from "../../utils/Api"


const settings = {
  dots: false,
  arrows: true,
  // nextArrow: <span className="arrow-left" />,
  // prevArrow: <span className="arrow-right" />,
  infinite: false,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 1,
  rows: 2,
  // fade : true,

  responsive: [
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 4,
        arrows: false,
        dots: true,
      },
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 2,
        arrows: false,
        dots: true,
        rows: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        arrows: false,
        dots: true,
        rows: 1,
      },
    },
  ],
}

const Photos = ({ show }) => {
  const [loading, setLoading] = useState(true)
  const [photos, setPhotos] = useState([])
  const [lightboxOpen, setLightboxOpen] = useState(false)
  const [lightboxSourceIndex, setLightboxSourceIndex] = useState(0)

  useEffect(() => {
    Api.getShowGallery(show.id).then(({ data: { data } }) => {
      setLoading(false)
      setPhotos(data.photos)
    })
  }, [show.id])

  const sources = useMemo(() => {
    return photos.map((photo) => {
      return photo.image_file
    })
  }, [photos])

  const types = useMemo(() => {
    return photos.map(() => {
      return "image"
    })
  }, [photos])

  return (
    <>
      <div className="photos">
        <div className="max-width">
          <div className="wrap">
            <h2 className="h2">OFFICIAL PHOTOS</h2>
            <SkeletonTheme color="#000" highlightColor="#444">
              <Slider {...settings} className="items">
                {loading &&
                  [1, 2, 3, 4].map((key) => (
                    <div className="item" key={"skeleton-" + key}>
                      <div className="img">
                        <Skeleton width="100%" height="100%" />
                      </div>
                    </div>
                  ))}

                {photos.map((photo, index) => (
                  <div className="item" key={photo.id}>
                    <div>
                      <a
                        href={"photo-" + photo.id}
                        onClick={(e) => {
                          e.preventDefault()
                          setLightboxOpen(!lightboxOpen)
                          setLightboxSourceIndex(index)
                        }}
                      >
                        <div
                          className="img"
                          style={{ backgroundImage: 'url("' + photo.image_file + '")' }}
                        />
                      </a>
                    </div>
                  </div>
                ))}
              </Slider>
            </SkeletonTheme>
          </div>
        </div>
      </div>
      <FsLightbox
        sourceIndex={lightboxSourceIndex}
        toggler={lightboxOpen}
        sources={sources}
        types={types}
      />
    </>
  )
}

export default Photos
