/* eslint-disable @typescript-eslint/no-empty-function */
import * as React from "react"
import { useHistory } from "react-router-dom"
import { Show } from "../types/get-shows"
import { Song } from "../types/get-songs"
import * as Api from "../utils/Api"

type EmptyObject = Record<number, unknown>

type ShowSongs = Record<number, Song>
type SongsByShowId = Record<number, ShowSongs | EmptyObject>
type SongsById = Record<number, Song>

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AnyFunction = (...args: any[]) => any

type WithAuthCheck = <CB extends AnyFunction>(
  cb: CB,
  pushToLogin?: boolean,
) => (...params: Parameters<CB>) => ReturnType<CB> | undefined

type WrappedWithAuthCheck<CB extends AnyFunction> = (
  ...args: Parameters<CB>
) => ReturnType<CB> | undefined

type ContextState = {
  isAuthenticated: boolean
  isLoading: boolean
  isLoaded: boolean
  songsById: SongsById
  songsByDspId: SongsById
  songsByShowId: SongsByShowId
  showsById: Record<number, Show>
  myShowsLoading: boolean
  myShowsLoaded: boolean
  myShows: number[]
  myShowRatings: Record<number, number>
  shows: Show[]
}
type ContextActions = {
  doLogout: () => void
  withAuthCheck: WithAuthCheck

  loadMyShows: WrappedWithAuthCheck<() => Promise<number[]>>
  addToMyShows: WrappedWithAuthCheck<(id: string) => void>
  removeFromMyShows: WrappedWithAuthCheck<(id: string) => void>
  updateShowRating: WrappedWithAuthCheck<
    (id: number, averageRating: number, myRating: number) => void
  >
}
type ContextValue = {
  state: ContextState
  actions: ContextActions
}

export type AppContextValue = ContextValue

const initialState: ContextValue = {
  state: {
    isAuthenticated: false,

    isLoading: false,
    isLoaded: false,

    songsById: {},
    songsByDspId: {},
    showsById: {},
    songsByShowId: {},

    myShowsLoading: false,
    myShowsLoaded: false,
    myShows: [],
    myShowRatings: {},
    shows: [],
  },
  actions: {
    doLogout: () => {},
    loadMyShows: () => Promise.resolve([]),
    removeFromMyShows: () => {},
    addToMyShows: () => {},
    updateShowRating: () => {},
    withAuthCheck: (cb) => cb(),
  },
}

export const AppContext = React.createContext<ContextValue>(initialState)
export const AppConsumer = AppContext.Consumer
export const useAppContext = () => {
  const context = React.useContext(AppContext)
  return context
}

type AppProviderProps = {
  authed: boolean
  children: React.ReactNode
}
export const AppProvider = (props: AppProviderProps) => {
  const [state, setState] = React.useState<ContextValue>({
    state: {
      ...initialState.state,
      isAuthenticated: props.authed,
    },
    actions: initialState.actions,
  })
  const history = useHistory()

  React.useEffect(() => {
    if (state.state.isLoaded || state.state.isLoading) {
      return
    }

    setState((c) => ({
      ...c,
      state: {
        ...c.state,
        isLoading: true,
      },
    }))

    getUser()

    Promise.all([fetchSongs(), fetchShows()])
      .then(() => {
        setState((c) => ({
          ...c,
          state: {
            ...c.state,
            isLoading: false,
            isLoaded: true,
          },
        }))
      })
      .catch(() => {
        setState((c) => ({
          ...c,
          state: {
            ...c.state,
            isLoading: false,
            isLoaded: true,
          },
        }))
      })
      .finally(() => {
        setState((c) => ({
          ...c,
          state: {
            ...c.state,
            isLoading: false,
            isLoaded: true,
          },
        }))
      })
  }, [state.state.isLoaded, state.state.isLoading])

  const fetchSongs = () => {
    return new Promise((resolve, reject) => {
      Api.getSongs()
        .then(({ data: { data } }) => {
          const songsById: ContextState["songsById"] = {}
          const songsByShowId: ContextState["songsByShowId"] = {}
          const songsByDspId: ContextState["songsByDspId"] = {}
          data.songs.forEach((song) => {
            songsById[parseInt(song.song_id)] = song
            songsByDspId[parseInt(song.id)] = song

            if (!songsByShowId[parseInt(song.show_id)]) {
              songsByShowId[parseInt(song.show_id)] = {}
            }
            songsByShowId[parseInt(song.show_id)][parseInt(song.song_id)] = song
          })

          setState((c) => ({
            ...c,
            state: {
              ...c.state,
              songs: data.songs,
              songsById,
              songsByShowId,
              songsByDspId,
            },
          }))

          resolve(true)
        })
        .catch(reject)
    })
  }

  const fetchShows = () => {
    return new Promise((resolve, reject) => {
      Api.getShows()
        .then(({ data: { data } }) => {
          const showsById: ContextState["showsById"] = {}
          data?.shows.forEach((show) => (showsById[parseInt(show.id)] = show))

          setState((c) => ({
            ...c,
            state: {
              ...c.state,
              shows: data?.shows ?? [],
              showsById,
            },
          }))

          resolve(true)
        })
        .catch(reject)
    })
  }

  const getUser = () => {
    return new Promise((resolve, reject) => {
      Api.getMe()
        .then(() => {
          setState((c) => ({
            ...c,
            state: {
              ...c.state,
              isAuthenticated: true,
            },
          }))

          resolve(true)
        })
        .catch(reject)
    })
  }

  const doLogout = () => {
    localStorage.removeItem("authToken")
    window.location.reload()
  }

  const loadMyShows = (): Promise<number[]> => {
    if (state.state.myShowsLoading || state.state.myShowsLoaded) {
      return Promise.resolve(state.state.myShows)
    }

    return new Promise((resolve) => {
      setState((c) => ({
        ...c,
        state: {
          ...c.state,
          myShowsLoading: true,
        },
      }))

      return Api.getMyShows().then(({ data: { data } }) => {
        setState((c) => ({
          ...c,
          state: {
            ...c.state,
            myShows: data.my_shows,
            myShowRatings: data.my_ratings,
            myShowsLoaded: true,
            myShowsLoading: false,
          },
        }))

        resolve(data.my_shows)
      })
    })
  }

  const removeFromMyShows = (id: string) => {
    const intId = parseInt(id)
    setState((c) => ({
      ...c,
      state: {
        ...c.state,
        myShows: state.state.myShows.filter((show_id) => show_id !== intId),
      },
    }))

    Api.removeFromMyShows(id).catch(() => {
      setState((c) => ({
        ...c,
        state: {
          ...c.state,
          myShows: [...state.state.myShows, intId],
        },
      }))
    })
  }

  const addToMyShows = (id: string) => {
    const intId = parseInt(id)
    setState((c) => ({
      ...c,
      state: {
        ...c.state,
        myShows: [...state.state.myShows, intId],
      },
    }))

    Api.addToMyShows(id).catch(() => {
      setState((c) => ({
        ...c,
        state: {
          ...c.state,
          myShows: state.state.myShows.filter((show_id) => show_id !== intId),
        },
      }))
    })

    return
  }

  const updateShowRating = (id: number, averageRating: number, myRating: number) => {
    const show = state.state.showsById[id]
    if (!show) {
      return
    }

    setState((c) => ({
      ...c,
      state: {
        ...c.state,
        showsById: {
          ...state.state.showsById,
          [id]: {
            ...show,
            rating: averageRating,
          },
        },
        myShowRatings: {
          ...state.state.myShowRatings,
          [id]: myRating,
        },
      },
    }))
  }

  const withAuthCheck: WithAuthCheck = (cb, pushToLogin = false) => {
    return (...args) => {
      if (state.state.isAuthenticated) {
        return cb(...args)
      }

      if (pushToLogin) {
        history.push("/login", {
          from: { pathname: history.location.pathname },
        })
      }
    }
  }

  return (
    <AppContext.Provider
      value={{
        state: state.state,
        actions: {
          doLogout,
          withAuthCheck,
          loadMyShows: withAuthCheck(loadMyShows),

          addToMyShows: withAuthCheck(addToMyShows, true),

          removeFromMyShows: withAuthCheck(removeFromMyShows, true),
          updateShowRating: withAuthCheck(updateShowRating, true),
        },
      }}
    >
      {props.children}
    </AppContext.Provider>
  )
}
