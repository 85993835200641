import SpotifyHelper from "../helpers/SpotifyHelper"
import { Song as DspSong } from "../types/get-songs"
import { Playlist } from "../types/playlist"
import Provider, { SongInfo, Status } from "./Provider"

export default class SpotifyProvider extends Provider {
  player: Spotify.Player | null = null
  currentTrack: Spotify.Track | null = null
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  errorCallback: (error: { message: string }) => void = () => {}
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  statusCallback: (...args: unknown[]) => unknown = () => {}
  providerName = "spotify"

  constructor(player: Spotify.Player) {
    super()

    this.player = player
  }

  play() {
    if (!this.player) {
      return Promise.reject("Invalid Player")
    }
    return this.player?.resume()
  }

  pause() {
    if (!this.player) {
      return Promise.reject("Invalid Player")
    }
    return this.player?.pause()
  }

  stop() {
    return this.player?.disconnect()
  }

  next() {
    if (!this.player) {
      return Promise.reject("Invalid Player")
    }
    return this.player?.nextTrack()
  }

  previous() {
    if (!this.player) {
      return Promise.reject("Invalid Player")
    }
    return this.player?.previousTrack()
  }

  seek(time: number) {
    if (!this.player) {
      return Promise.reject("Invalid Player")
    }
    return this.player?.seek(time)
  }

  async info() {
    if (!this.player) {
      return Promise.reject("Invalid Player")
    }
    const currentState = await this.player?.getCurrentState()
    if (currentState === null) return Promise.reject()
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const { current_track: currentTrack } = currentState

    if (currentTrack) {
      return Promise.resolve(this.trackToSongInfo(currentTrack))
    }

    return Promise.resolve(null)
  }

  onSongChange(handler: (...args: unknown[]) => unknown) {
    this.player?.addListener("player_state_changed", (e) => {
      if (!e?.track_window) {
        return
      }

      const { current_track: newTrack } = e?.track_window
      const trackChanged =
        (newTrack !== null && this.currentTrack === null) || this.currentTrack?.uri !== newTrack.uri

      this.currentTrack = newTrack

      if (trackChanged) {
        handler(this.trackToSongInfo(this.currentTrack))
      }
    })
  }

  onPlayerStatusChange(handler: (...args: unknown[]) => unknown) {
    this.statusCallback = handler

    this.player?.addListener("player_state_changed", (e) => {
      let status: typeof Status[keyof typeof Status] = Status.PLAYING

      if (e?.paused) {
        status = Status.PAUSED
      }

      if (!e?.track_window) {
        status = Status.STOPPED
      }

      handler(status)
    })
  }

  onSongProgressChange(_handler: (...args: unknown[]) => unknown) {
    this._warning("onSongProgressChange")
  }

  onError(handler: (...args: unknown[]) => unknown) {
    const callback = ({ message }: { message: string }) => {
      handler({ message })
      console.error("onError", message)
    }

    this.errorCallback = callback
    this.player?.on("initialization_error", callback)
    this.player?.on("authentication_error", callback)
    this.player?.on("account_error", callback)
    this.player?.on("playback_error", callback)
  }

  dispose() {
    const handlers = [
      "player_state_changed",
      "initialization_error",
      "authentication_error",
      "account_error",
      "playback_error",
      "ready",
    ] as const

    handlers.forEach((eventName) => {
      this.player?.removeListener(eventName)
    })

    this.stop()
  }

  setSong(song: DspSong) {
    if (this.player) {
      this.statusCallback(Status.LOADING)
      return SpotifyHelper.playTrackRequest(this.player, song.dsp_spotify_id)
    }
    return Promise.reject("Invalid Player")
  }

  setPlaylist(playlist: Playlist) {
    this.statusCallback(Status.LOADING)
    const playlistId = playlist.dsp ? playlist.dsp_id : playlist.dsp_spotify_id

    if (playlist.dsp && playlist.dsp !== "spotify") {
      return Promise.reject("Invalid playlist")
    }
    if (!this.player) {
      return Promise.reject("Invalid Player")
    }
    return SpotifyHelper.playPlaylistRequest(this.player, playlistId)
  }

  trackToSongInfo(track: Spotify.Track) {
    return new SongInfo(
      track.name,
      track.artists[0].name,
      track.album.images[0].url,
      "00:00",
      track.uri,
    )
  }
}
