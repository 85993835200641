import * as React from "react"
import { SkeletonTheme } from "react-loading-skeleton"
import sanitizeHtml from "sanitize-html"
import { Summary } from "../../types/get-shows"

type FanSummaryProps = {
  index: number
  summary: Summary
}
const FanSummary = ({ index, summary }: FanSummaryProps) => {
  const { ambassador, highlight_tracks: tracks, rarities, summary: summaryText } = summary

  return (
    <section className="fan-recap">
      <div className="max-width">
        <div className="wrap">
          {index === 0 && <h2 className="h2">Fan Recaps</h2>}
          <SkeletonTheme color="#000" highlightColor="#444">
            <div className="fan-recap-content">
              <div>
                <div>
                  <h3>Highlight Tracks</h3>
                  <p>{tracks}</p>
                </div>
                <div>
                  <h3>By</h3>
                  <p>{ambassador}</p>
                </div>
              </div>

              <div>
                <div>
                  <h3>Rarities</h3>
                  <p>{rarities}</p>
                </div>
              </div>

              <div>
                <div>
                  <h3>Summary</h3>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: sanitizeHtml(summaryText),
                    }}
                  />
                </div>
              </div>
            </div>
          </SkeletonTheme>
        </div>
      </div>
    </section>
  )
}

export default FanSummary
