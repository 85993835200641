/* eslint-disable no-return-assign */
import * as React from "react"
import { Link } from "react-router-dom"
import Logo from "../../assets/img/Deep-Logo-beta.png"
import Player from "../Player"

const Footer = () => {
  const [lastPoint, setLastPoint] = React.useState(0)
  const [playerClasses, setPlayerClasses] = React.useState<string[]>([])
  const footerRef = React.useRef<HTMLElement>(null)
  const playerRef = React.useRef<HTMLDivElement>(null)

  const listenToScroll = React.useCallback(() => {
    const scrollTop = document.documentElement.scrollTop
    const windowHeight = window.innerHeight

    const classes = []

    if (scrollTop + windowHeight < (footerRef?.current?.offsetTop ?? 0)) {
      classes.push("_fxpl")
    }

    if (scrollTop > lastPoint) {
      classes.push("_shw")
    }

    if (classes[0] !== playerClasses[0] || classes[1] !== playerClasses[1]) {
      setPlayerClasses(classes)
    }

    setLastPoint(scrollTop)
  }, [lastPoint, playerClasses])

  React.useEffect(() => {
    window.addEventListener("scroll", listenToScroll)
    return () => window.removeEventListener("scroll", listenToScroll)
  }, [listenToScroll])

  return (
    <>
      <Player ref={playerRef} classes={playerClasses} />

      <footer className="footer" id="footer" ref={footerRef}>
        <div className="max-width">
          <div className="wrap">
            <div className="d-flex jc-space-b navbar-footer">
              <div className="brand">
                <Link to="/">
                  <img src={Logo} alt="" />
                </Link>
              </div>

              <div className="navmenu text-center">
                <ul>
                  <li>
                    <Link to="/">Featured</Link>
                  </li>
                  <li>
                    <Link to="/shows">All Shows</Link>
                  </li>
                  <li>
                    <Link to="/my-shows">My Shows</Link>
                  </li>
                  <li>
                    <Link to="/my-playlists">My Playlists</Link>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      rel="noreferrer nofollow"
                      href="https://generator.pearljam.com/"
                    >
                      Setlist Generator
                    </a>
                  </li>
                </ul>
              </div>

              <div className="SM text-right">
                <ul>
                  <li>
                    <a
                      href="https://instagram.com/pearljam"
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      <i className="fa fa-instagram" />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://facebook.com/PearlJam"
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      <i className="fa fa-facebook-square" />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://twitter.com/pearljam"
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      <i className="fa fa-twitter-square" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <div className="copyright">
              <a
                href="https://help.pearljam.com/hc/en-us/articles/204866734-Terms-of-Use"
                target="_blank"
                rel="noreferrer"
              >
                Terms of Use
              </a>
              &nbsp;&nbsp;|&nbsp;&nbsp;
              <a
                href="https://help.pearljam.com/hc/en-us/articles/204866604-Privacy-Policy"
                target="_blank"
                rel="noreferrer"
              >
                Privacy Policy
              </a>
              &nbsp;&nbsp;|&nbsp;&nbsp;
              <a href="https://help.pearljam.com/hc/en-us" target="_blank" rel="noreferrer">
                Support
              </a>{" "}
              &nbsp;&nbsp;
              <br />
              &copy; {new Date().getFullYear()} Pearl Jam. &reg; All rights reserved
            </div>
          </div>
        </div>
      </footer>
    </>
  )
}
export default Footer
