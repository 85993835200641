import React, { Component } from "react"

class NotFound extends Component {
  render() {
    return (
      <div className="wrap loading">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 950 369.196">
          <g id="Pulse" transform="translate(-100.151 -205.838)">
            <path
              d="M959.414,431.813l9.11,62.623,9.719-40.937a3.219,3.219,0,0,1,3.387-2.5,3.26,3.26,0,0,1,3.033,2.921l3.387,33.051,10.179-39.8a3.262,3.262,0,0,1,6.074-.658l10.662,21.222h415.011a3.261,3.261,0,1,1,0,6.522H1012.955a3.265,3.265,0,0,1-2.915-1.8l-7.653-15.229-12.555,49.1a3.262,3.262,0,0,1-6.4-.475l-3.29-32.094-9.21,38.795a3.262,3.262,0,0,1-6.4-.283l-8.46-58.151Z"
              transform="translate(-383.088 -101.141)"
              stroke="#ed1c24"
              strokeWidth="2"
              fill="#ed1c24"
              fillOpacity="0.0"
            />
            <path
              d="M885.812,355.388c-3.595,22.814-8.641,54.344-9.4,56.625a3.4,3.4,0,0,1-3.518,2.257c-3.269-.385-3.83-.448-12.34-95.779L835.563,574.5a3.261,3.261,0,0,1-3.246,2.944H832.3a3.261,3.261,0,0,1-3.233-2.975L799.1,234.964l4.454-23.737L832.48,538.935l24.979-255.917a3.261,3.261,0,0,1,3.245-2.944h.016a3.26,3.26,0,0,1,3.232,2.973c2.978,33.665,7.178,79.533,9.881,105.961,2.645-16.241,6.2-38.75,8.877-55.956a3.34,3.34,0,0,1,3.238-2.76,3.26,3.26,0,0,1,3.211,2.792Z"
              transform="translate(-312.834 -2.412)"
              fill="#ed1c24"
              fillOpacity="0.0"
              stroke="#ed1c24"
              strokeWidth="2"
            />
            <path
              d="M714.968,232.552,684.106,392.443a3.262,3.262,0,0,1-6.328.315L670,366.73l-4.819,24.67a3.263,3.263,0,0,1-3.2,2.637h-.044a3.261,3.261,0,0,1-3.173-2.721l-4.333-25.866-9.22,48.946a3.3,3.3,0,0,1-3.346,2.655,3.259,3.259,0,0,1-3.106-2.931L628.365,311.5l-11.352,57.933-5.885-3.888,15.064-76.882a3.3,3.3,0,0,1,3.353-2.632,3.262,3.262,0,0,1,3.094,2.931l10.34,102.058,8.436-44.788a3.262,3.262,0,0,1,3.206-2.659h.034a3.262,3.262,0,0,1,3.183,2.722l4.391,26.208,3.909-20.016a3.261,3.261,0,0,1,6.326-.309l7.757,25.97,32.75-169.665a3.261,3.261,0,0,1,6.452.333Z"
              transform="translate(-228.702)"
              fill="#ed1c24"
              fillOpacity="0.0"
              stroke="#ed1c24"
              strokeWidth="2"
            />
            <path
              d="M388.312,498.828a3.26,3.26,0,0,1-3.2,2.635h-281.7a3.261,3.261,0,1,1,0-6.523H382.426Z"
              transform="translate(0 -129.395)"
              fill="#ed1c24"
              fillOpacity="0.0"
              stroke="#ed1c24"
              strokeWidth="2"
            />
          </g>
        </svg>
      </div>
    )
  }
}

export default NotFound
