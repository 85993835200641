import React, { Component } from "react"
import MyShow from "../sections/MyShow"
import MyShowsSection from "../sections/MyShowsSection"
import PlayList from "../sections/PlayList"

export default class MyShows extends Component {
  render() {
    return (
      <div>
        <MyShow />
        <MyShowsSection />
        <PlayList />
      </div>
    )
  }
}
