import * as React from "react"
import AllShows from "../sections/AllShows"
import Banner from "../sections/Banner"
import CuratedPlaylistGallery from "../sections/CuratedPlaylistGallery"
import PlayList from "../sections/PlayList"

const Home = () => {
  return (
    <div>
      <Banner />

      <CuratedPlaylistGallery />

      <AllShows />

      <PlayList />
    </div>
  )
}

export default Home
