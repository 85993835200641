import * as React from "react"
import Skeleton, { SkeletonTheme } from "react-loading-skeleton"
import { Link } from "react-router-dom"
import Slider from "react-slick"
import Thumbnail from "../../assets/img/Playlist-Thumbnail.png"
import { useAppContext } from "../../contexts/AppContext"
import { ManagePlaylistsConsumer } from "../../contexts/ManagePlaylistsContext"
import { MusicPlayerContextConsumer } from "../../contexts/MusicPlayerContext"
import AddToList from "../../svgs/AddToList"

const settings = {
  dots: false,
  arrows: true,
  // nextArrow: <span className="arrow-left" />,
  // prevArrow: <span className="arrow-right" />,
  infinite: false,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 4,
  // fade : true,

  responsive: [
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
      },
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        dots: true,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true,
      },
    },
  ],
}

const MyPlayLists = () => {
  const appContext = useAppContext()

  if (!appContext.state.isAuthenticated) {
    return null
  }

  return (
    <div className="myPlayList">
      <div className="max-width">
        <div className="wrap">
          <h2 className="h2">My Playlists</h2>
          <MusicPlayerContextConsumer>
            {(context) => (
              <SkeletonTheme color="#000" highlightColor="#444">
                <ManagePlaylistsConsumer>
                  {(playlistsContext) => {
                    playlistsContext.actions.loadPlaylists()

                    if (
                      !playlistsContext.state.playlistsLoading &&
                      playlistsContext.state.playlists.length === 0
                    ) {
                      return (
                        <h3 className="h3">
                          Click the icon <AddToList /> next to any song to add it to a playlist.
                        </h3>
                      )
                    }

                    return (
                      <Slider {...settings} className="items">
                        {playlistsContext.state.playlistsLoading &&
                          [1, 2, 3, 4].map((key) => (
                            <div className="item" key={key}>
                              <div className="img">
                                <Skeleton width="100%" height="100%" />
                              </div>
                            </div>
                          ))}

                        {playlistsContext.state.playlists.map((playlist) => (
                          <div className="item" key={playlist.id}>
                            <div
                              className="img"
                              style={{
                                backgroundImage:
                                  'url("' +
                                  (playlist.cover_image ? playlist.cover_image : Thumbnail) +
                                  '")',
                              }}
                            >
                              {context.canPlayPlaylist(playlist) && (
                                <button
                                  type="button"
                                  className="playBtn"
                                  onClick={() => context.playPlaylist(playlist)}
                                >
                                  <i className="fa fa-play" />
                                </button>
                              )}
                            </div>

                            <h3 className="h3">{playlist.name}</h3>

                            <Link className="listenNow" to={"/my-playlists/" + playlist.id}>
                              VIEW PLAYLIST <i className="fa fa-chevron-right" />
                            </Link>
                          </div>
                        ))}
                      </Slider>
                    )
                  }}
                </ManagePlaylistsConsumer>
              </SkeletonTheme>
            )}
          </MusicPlayerContextConsumer>
        </div>
      </div>
    </div>
  )
}

export default MyPlayLists
