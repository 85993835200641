import { Playlist } from "./../types/playlist"
/* eslint-disable @typescript-eslint/ban-ts-comment */
import Provider, { SongInfo, Status } from "./Provider"

/* eslint-disable no-restricted-globals */
export default class AppleProvider extends Provider {
  musicKit: MusicKit.MusicKitInstance | null = null
  providerName = "apple"

  constructor(musicKit: MusicKit.MusicKitInstance) {
    super()
    this.musicKit = musicKit
  }

  play() {
    if (!this.musicKit?.player) {
      return Promise.reject("Invalid Player")
    }
    return this.musicKit.player.play()
  }

  pause() {
    if (!this.musicKit?.player) {
      return Promise.reject("Invalid Player")
    }
    return this.musicKit.player.pause()
  }

  stop() {
    return this.musicKit?.player.stop()
  }

  next() {
    if (!this.musicKit?.player) {
      return Promise.reject("Invalid Player")
    }
    return this.musicKit.player.skipToNextItem()
  }

  seek(time: number) {
    if (!this.musicKit?.player) {
      return Promise.reject("Invalid Player")
    }
    // TODO: Workout what the seekToTime() is expecting to be passed, docs aren't useful.
    return this.musicKit.player.seekToTime(time)
  }

  previous() {
    if (!this.musicKit?.player) {
      return Promise.reject("Invalid Player")
    }
    return this.musicKit.player.skipToPreviousItem()
  }

  info() {
    let info = null

    if (this.musicKit?.player.isPlaying) {
      const item = this.musicKit?.player.nowPlayingItem

      info = this.itemToSongInfo(item)
    }

    return Promise.resolve(info)
  }

  onSongChange(handler: (...args: any[]) => void) {
    // @ts-ignore
    this.musicKit?.addEventListener("mediaItemDidChange", (e: { item: any }) => {
      let songInfo = null

      if (e.item) {
        const { item } = e
        songInfo = this.itemToSongInfo(item)
      }

      handler(songInfo)
    })
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    this.musicKit?.addEventListener("queueItemsDidChange", (e: string | unknown[]) => {
      let songInfo = null

      if (e.length > 0) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const [item] = e
        songInfo = this.itemToSongInfo(item)
      }

      handler(songInfo)
    })
  }

  onPlayerStatusChange(handler: (arg0: string) => void) {
    // @ts-ignore
    this.musicKit?.addEventListener("playbackStateDidChange", (e: { state: any }) => {
      handler(this.mapStateToApplicationState(e.state))
    })
  }

  onSongProgressChange(handler: { (...args: unknown[]): unknown; (arg0: any): void }) {
    // @ts-ignore
    this.musicKit?.addEventListener("playbackTimeDidChange", (e: { currentPlaybackTime: any }) => {
      handler(window.MusicKit.formatMediaTime(e.currentPlaybackTime, ":"))
    })
  }

  onError(handler: (...args: unknown[]) => unknown) {
    // @ts-ignore
    this.musicKit?.addEventListener("mediaPlaybackError", (e: { message: string }) => {
      console.error(e)
      handler(e)
    })
  }

  dispose() {
    this.stop()
    const handlers = [
      "mediaPlaybackError",
      "mediaItemDidChange",
      "queueItemsDidChange",
      "playbackTimeDidChange",
      "playbackStateDidChange",
    ] as const

    handlers.forEach((eventName) => {
      // @ts-ignore
      this.musicKit?.removeEventListener(eventName)
    })
  }
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  setSong(song: { dsp_apple_music_id: any }) {
    if (!this.musicKit) return Promise.reject()
    // @ts-ignore
    return this.musicKit?.setQueue({
      song: song.dsp_apple_music_id,
    })
  }

  setPlaylist(playlist: Playlist) {
    const playlistId = playlist.dsp ? playlist.dsp_id : playlist.dsp_apple_music_id

    if (!this.musicKit || (playlist.dsp && playlist.dsp !== "apple")) {
      return Promise.reject("Invalid playlist")
    }

    if (playlistId.indexOf("pl.") > -1 || playlistId.indexOf("p.") > -1) {
      // @ts-ignore
      return this.musicKit.setQueue({
        playlist: playlistId,
      })
    }
    // @ts-ignore
    return this.musicKit.setQueue({
      album: playlistId,
    })
  }

  mapStateToApplicationState(state: MusicKit.PlaybackStates) {
    switch (state) {
      case window.MusicKit.PlaybackStates.paused:
        return Status.PAUSED
      case window.MusicKit.PlaybackStates.playing:
        return Status.PLAYING
      case window.MusicKit.PlaybackStates.seeking:
        return Status.LOADING
      case window.MusicKit.PlaybackStates.stalled:
        return Status.PAUSED
      case window.MusicKit.PlaybackStates.waiting:
        return Status.LOADING
      case window.MusicKit.PlaybackStates.loading:
        return Status.LOADING
      default:
        return Status.STOPPED
    }
  }

  itemToSongInfo(item: MusicKit.MediaItem) {
    return new SongInfo(
      item.title,
      item.artistName,
      item.artworkURL,
      window.MusicKit.formatMediaTime(item.playbackDuration / 1000, ":"),
    )
  }
}
