import React, { Component } from "react"
import Skeleton, { SkeletonTheme } from "react-loading-skeleton"
import { Link } from "react-router-dom"
import Slider from "react-slick"
import CoverThumnail from "../../assets/img/Playlist-Thumbnail.png"
import { MusicPlayerContextConsumer } from "../../contexts/MusicPlayerContext"
import * as Api from "../../utils/Api"




const settings = {
  dots: false,
  arrows: true,
  // nextArrow: <span className="arrow-left" />,
  // prevArrow: <span className="arrow-right" />,
  infinite: false,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 3,
  responsive: [
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        dots: true,
      },
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true,
      },
    },
  ],
}

export default class CuratedPlaylistGallery extends Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: true,
      playlists: [],
    }
  }

  componentDidMount() {
    Api.getFeaturedPlaylists().then(({ data: { data } }) => {
      this.setState({
        loading: false,
        playlists: data.featured_playlists,
      })
    })
  }

  render() {
    if (!this.state.loading && this.state.playlists.length < 1) {
      return null
    }

    return (
      <div className="playList">
        <div className="max-width">
          <div className="wrap">
            <MusicPlayerContextConsumer>
              {(context) => (
                <SkeletonTheme color="#000" highlightColor="#444">
                  <Slider {...settings} className="items">
                    {this.state.loading &&
                      [1, 2, 3].map((key) => (
                        <div className="item" key={"loading" + key}>
                          <div className="img">
                            <Skeleton width="100%" height="100%" />
                          </div>
                        </div>
                      ))}

                    {this.state.playlists.map((playlist) => (
                      <div className="item" key={playlist.id}>
                        <div
                          className="img img--square"
                          style={{
                            backgroundImage:
                              'url("' +
                              (playlist.image !== "" ? playlist.image : CoverThumnail) +
                              '")',
                          }}
                        >
                          <button
                            type="button"
                            className="playBtn"
                            onClick={() => context.playPlaylist(playlist)}
                          >
                            <i className="fa fa-play" />
                          </button>
                        </div>

                        <h3 className="h3">
                          {playlist.name} <br />
                          <span>{playlist.author}</span>
                        </h3>

                        <div className="p">
                          <p>{playlist.description}</p>
                        </div>

                        <Link className="listenNow" to={"/featured-playlists/" + playlist.id}>
                          VIEW PLAYLIST <i className="fa fa-chevron-right" />
                        </Link>
                      </div>
                    ))}
                  </Slider>
                </SkeletonTheme>
              )}
            </MusicPlayerContextConsumer>
          </div>
        </div>
      </div>
    )
  }
}
