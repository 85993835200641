import React, { useContext, useEffect, useMemo, useState } from "react"
import { Redirect } from "react-router-dom"
import { AppContext } from "../../contexts/AppContext"
import { GetSong } from "../../types/get-song"
import { Song } from "../../types/get-songs"
import * as Api from "../../utils/Api"
import Banner from "../sections/Banner"
import SongDetails from "../sections/SongDetails"

type SingleSong = {
  match: any
}
const SingleSong = ({ match }: SingleSong) => {
  const {
    params: { showId, songId },
  } = match
  const { state } = useContext(AppContext)
  const [loading, setLoading] = useState(true)
  const [songDetails, setSongDetails] = useState<GetSong["data"]["song"] | null>()

  const song = useMemo<Song | null>(() => {
    const show = state.songsByShowId?.[showId]
    if (show && Object.keys(show).length > 0) {
      return (show?.[songId] ?? null) as Song | null
    }
    return null
  }, [state?.songsByShowId, showId, songId])

  useEffect(() => {
    if (song) {
      Api.getDspSong(song.id).then(({ data: { data } }) => {
        setLoading(false)
        setSongDetails(data.song)
      })
    }
  }, [song])

  if (!song) {
    return <Redirect to="/not-found" />
  }

  return (
    <>
      <div className="single-song">
        <div className="max-width">
          <div className="wrap">
            <SongDetails loading={loading} song={song} details={songDetails} />
          </div>
        </div>
      </div>
      <Banner />
    </>
  )
}

export default SingleSong
