import React, { Component } from "react"

export default class LoginForm extends Component {
  constructor(props) {
    super(props)

    this.state = {
      email: "",
      password: "",
      persistLogin: false,
    }

    this.onChange = this.onChange.bind(this)
  }

  onChange(e) {
    let value = e.target.value

    if (e.target.name === "persistLogin") {
      value = e.target.checked
    }

    this.setState({
      [e.target.name]: value,
    })
  }

  render() {
    return (
      <form
        onSubmit={(e) => {
          e.preventDefault()
          this.props.doLogin(this.state.email, this.state.password, this.state.persistLogin)
        }}
      >
        <h2 className="h2">PEARL JAM LOG IN</h2>
        <div className="_frm">
          {Boolean(this.props.error) && <h3 style={{ color: "red" }}>{this.props.error}</h3>}
          <div className="_frmg">
            <input
              type="text"
              name="email"
              id="_email"
              placeholder="E-MAIL ADDRESS"
              value={this.state.email}
              onChange={this.onChange}
            />
          </div>
          <div className="_frmg">
            <input
              type="password"
              name="password"
              id="_password"
              placeholder="PASSWORD"
              value={this.state.password}
              onChange={this.onChange}
            />
          </div>
          <div className="_frmg">
            <label>
              <input
                type="checkbox"
                name="persistLogin"
                id="_persistLogin"
                value="1"
                onChange={this.onChange}
                checked={this.state.persistLogin}
              />
              &nbsp;Keep me logged in
            </label>
            <div className="forgotPassword">
              <a
                href={process.env.REACT_APP_SITE_URL + "ten-club/forgotten-password"}
                target="_blank"
                rel="noreferrer"
                id="LoginButton"
              >
                Forgotten Password
              </a>
            </div>
          </div>
          <button className="button full gris" id="LoginButton" disabled={this.props.loading}>
            {this.props.loading ? "Logging In..." : "Login >"}
          </button>
        </div>
      </form>
    )
  }
}
