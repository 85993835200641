import * as React from "react"
import { MusicPlayerContext } from "../../contexts/MusicPlayerContext"
import DeleteIcon from "../../svgs/DeleteIcon"

export default function SelectDSPModal() {
  const context = React.useContext(MusicPlayerContext)
  if (!context.state.showSelector) {
    return null
  }

  return (
    <div className="_modal">
      <button className="close" onClick={context.closeSelector}>
        <DeleteIcon fill="#fff" />
      </button>
      <div className="_modal-wrap">
        <div className="_modal-body">
          <div className="entry-link">Please select your music provider</div>

          <div className="dsp-select">
            <button
              type="button"
              className="spotify"
              onClick={() => context.selectProvider("spotify")}
            >
              Spotify
            </button>

            <button
              type="button"
              className="youtube"
              onClick={() => context.selectProvider("youtube")}
            >
              Youtube
            </button>

            <button type="button" className="apple" onClick={() => context.selectProvider("apple")}>
              Apple Music
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
