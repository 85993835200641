import React, { useContext, useEffect, useState } from "react"
import { useAppContext } from "../../contexts/AppContext"
import { ManagePlaylistsContext } from "../../contexts/ManagePlaylistsContext"
import { MusicPlayerContext } from "../../contexts/MusicPlayerContext"
import AddToList from "../../svgs/AddToList"
import Amazon from "../../svgs/Amazon"
import Apple from "../../svgs/Apple"
import Play from "../../svgs/Play"
import { rateShowSong } from "../../utils/Api"
import Rating from "../Rating"

const ALBUM_IMAGE_PATH = "https://downloads-pearljam-com.s3.amazonaws.com/img/album-art"

const Details = ({ loading, song, details }) => {
  const musicPlayer = useContext(MusicPlayerContext)
  const { actions: playlistActions } = useContext(ManagePlaylistsContext)
  const appContext = useAppContext()
  const [averageRating, setAverageRating] = useState(details?.rating)

  useEffect(() => {
    setAverageRating(details?.rating)
  }, [details?.rating])

  const { album } = song
  const show = appContext.state.showsById[song.show_id]

  const albumImageUrl = `${ALBUM_IMAGE_PATH}/${album.image}`

  const handleRate = (r) => {
    rateShowSong(song.show_id, song.song_id, r).then(({ data }) => {
      const {
        data: {
          data: { rating: updatedRating },
        },
      } = data
      setAverageRating(updatedRating)
    })
  }

  if (!details) return null

  return (
    <div className="_rwss d-flex jc-space-b f-wrap">
      <div className="_clss">
        {album && (
          <div className="thumbnail">
            <img src={albumImageUrl} alt={album.name} />
          </div>
        )}
      </div>
      <div className="_clss">
        {loading && (
          <h1 className="h1">
            {song.name}
            <span>{album ? "appears on " + album.name : ""}</span>
          </h1>
        )}

        {!loading && (
          <>
            <h1 className="h1">
              {song.name}{" "}
              <button className="transparentBtn play" onClick={() => musicPlayer.playSong(song)}>
                <Play />
              </button>
              <span>
                {show.name} | {show.date_pretty}
                <br />
                <br />
                {album ? "appears on " + album.name + " | " : ""}
                {album ? <br /> : ""}
                RELEASED <b>{details.released}</b>{" "}
              </span>
            </h1>

            <h2 className="h2">
              Times Played <span>{details.play_count}</span>
            </h2>

            <div className="items d-flex jc-space-b f-wrap">
              <div>
                <div className="entry-n">First Played</div>
                <div className="entry-d">{details.first_show_date}</div>
              </div>
              <div>
                <div className="entry-n">Last Played</div>
                <div className="entry-d">{details.last_show_date}</div>
              </div>
              {(details.purchase_url_amazon || details.purchase_url_apple) && (
                <div>
                  <div className="entry-n">Purchase Song</div>
                  <div className="entry-p">
                    <a href={details.purchase_url_apple} target="_blank" rel="noreferrer">
                      <Apple width="40" />
                    </a>
                    <a href={details.purchase_url_amazon} target="_blank" rel="noreferrer">
                      <Amazon />
                    </a>
                  </div>
                </div>
              )}
            </div>
          </>
        )}
        <div>
          <Rating currentRating={averageRating} editable={false} label="Average Rating" />
          <Rating
            currentRating={details?.my_rating}
            label="My Rating"
            onRate={appContext.actions.withAuthCheck(handleRate, true)}
          />
          <br />
        </div>

        <button className="button" onClick={() => playlistActions.addSongs([song])}>
          <AddToList /> ADD TO PLAYLIST
        </button>
      </div>
    </div>
  )
}

export default Details
