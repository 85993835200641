import * as React from "react"
import { useAppContext } from "../contexts/AppContext"
import { MusicPlayerContext } from "../contexts/MusicPlayerContext"
import { Status } from "../providers/Provider"
import { Show } from "../types/get-shows"

type PlayerProps = {
  classes: string[]
}
const Player = React.forwardRef<HTMLDivElement, PlayerProps>(function Player(props, ref) {
  const { state } = React.useContext(MusicPlayerContext)
  const context = useAppContext()
  const show = React.useMemo<Show | null>(() => {
    const uri = state?.currentSong?.uri
    const song = Object.values(context.state.songsByDspId).find(
      (v) =>
        v?.dsp_spotify_id === uri || v?.dsp_apple_music_id === uri || v?.dsp_youtube_id === uri,
    )
    if (!song) {
      return null
    }
    return context.state.showsById?.[Number(song?.show_id)] ?? null
  }, [context.state.showsById, context.state.songsByDspId, state?.currentSong?.uri])

  const { playerProvider, currentSong, playerStatus } = state
  const play = () => {
    playerProvider?.play()
  }

  const pause = () => {
    playerProvider?.pause()
  }

  const previous = () => {
    playerProvider?.previous()
  }

  const next = () => {
    playerProvider?.next()
  }

  const isLoading = playerStatus === Status.LOADING
  const canPlay = playerStatus === Status.STOPPED || playerStatus === Status.PAUSED
  const playing = playerStatus === Status.PLAYING

  const visible = (currentSong || isLoading) && !!playerProvider

  return (
    <div
      className={"_dps-player " + (!visible ? " hidden " : "") + props.classes.join(" ")}
      id="_dps-player"
      ref={ref}
    >
      {visible && (
        <div className="wrapper">
          <div className="max-width">
            <div className="wrap">
              <div className="d-flex jc-space-b align-center">
                <div className="_cl76" style={{ height: "76px" }}>
                  {typeof currentSong?.artworkImageUrl === "string" && (
                    <img
                      src={currentSong.artworkImageUrl.replace("{w}", "76").replace("{h}", "76")}
                      height="76"
                      alt=""
                    />
                  )}
                </div>

                <div className="_cl80c d-flex flex-1 m-0-80 jc-space-b align-center">
                  <div className="musicMeta">
                    <span className="musicName">
                      {currentSong ? currentSong.songTitle : "Loading..."}
                    </span>
                    <div className="musicDate">
                      <span>{show?.date_pretty}</span>
                    </div>
                  </div>

                  <div className="controls">
                    <div className="d-flex jc-space-b align-center">
                      <button className="prevAudio" type="button" onClick={() => previous()}>
                        <i className="fa fa-step-backward" aria-hidden="true" />
                      </button>
                      {canPlay && (
                        <button className="playAudio" type="button" onClick={() => play()}>
                          <i className="fa fa-play" aria-hidden="true" />
                        </button>
                      )}
                      {playing && (
                        <button className="pauseAudio" type="button" onClick={() => pause()}>
                          <i className="fa fa-pause" aria-hidden="true" />
                        </button>
                      )}
                      {isLoading && (
                        <button
                          type="button"
                          className="pauseAudio"
                          onClick={() => pause()}
                          disabled={true}
                        >
                          <i className="fa fa-spinner fa-spin" aria-hidden="true" />
                        </button>
                      )}
                      <button className="nextAudio" type="button" onClick={() => next()}>
                        <i className="fa fa-step-forward" aria-hidden="true" />
                      </button>
                    </div>
                  </div>
                  {/* <div className="volume">
                              <div className="d-flex jc-space-b align-center">
                                  <i className="fa fa-volume-off" aria-hidden="true"></i>
                                  &nbsp;&nbsp;
                                  <input type="range" name="volume" id="_volume" />
                                  &nbsp;&nbsp;
                                  <i className="fa fa-volume-up" aria-hidden="true"></i>
                              </div>
                          </div> */}
                </div>
                <div className="_cl76" />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
})
export default Player
