import React, { Component } from "react"
import { Link } from "react-router-dom"
import DeleteIcon from "../../svgs/DeleteIcon"
import Play from "../../svgs/Play"
import Background from "../../assets/img/My-Shows-Background.png"

import { AppContext } from "../../contexts/AppContext"
import { MusicPlayerContextConsumer } from "../../contexts/MusicPlayerContext"

import Skeleton, { SkeletonTheme } from "react-loading-skeleton"

export default class MyShowsSection extends Component {
  componentDidMount() {
    this.context.actions.loadMyShows()
  }

  render() {
    return (
      <div className="MyShowsSection" style={{ backgroundImage: "url(" + Background + ")" }}>
        <div className="max-width">
          <div className="wrap">
            <h2 className="h2 center">My Shows</h2>

            <SkeletonTheme color="#000" highlightColor="#444">
              <div className="items d-flex f-wrap">
                {this.context.state.myShowsLoading &&
                  [1, 2, 3, 4].map((key) => (
                    <div className="item" key={key}>
                      <div className="img">
                        <Skeleton width="100%" height="100%" />
                      </div>
                    </div>
                  ))}

                {this.context.state.myShowsLoaded && (
                  <MusicPlayerContextConsumer>
                    {(context) =>
                      this.context.state.myShows.map((show_id) => {
                        const show = this.context.state.showsById[show_id]
                        return (
                          <div className="item" key={show_id}>
                            <div className="img">
                              <button
                                className="delete transparentBtn"
                                onClick={() => this.context.actions.removeFromMyShows(show.id)}
                              >
                                <DeleteIcon />
                              </button>
                              <div
                                className="bg"
                                style={{
                                  backgroundImage:
                                    'url("https://downloads-pearljam-com.s3.amazonaws.com/img/show-poster/' +
                                    show.poster_file +
                                    '")',
                                }}
                              />
                            </div>
                            <div className="_dts">
                              <h4 className="h4">{show.date}</h4>
                              <h5 className="h5">
                                {show.venue_name}
                                <br />
                                <span>{show.city_state}</span>
                              </h5>

                              <button
                                className="transparentBtn play"
                                onClick={() => context.playPlaylist(show)}
                              >
                                <Play />
                              </button>

                              <Link to={"/shows/" + show.id} className="show-details">
                                Show Details {">"}
                              </Link>
                            </div>
                          </div>
                        )
                      })
                    }
                  </MusicPlayerContextConsumer>
                )}
              </div>
              {this.context.state.myShowsLoaded && this.context.state.myShows.length === 0 && (
                <h3 className="h3 center">You have no shows.</h3>
              )}
            </SkeletonTheme>

            <Link to="/shows" className="button full black">
              + ADD NEW SHOW {">"}
            </Link>
          </div>
        </div>
      </div>
    )
  }
}

MyShowsSection.contextType = AppContext
