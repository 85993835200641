import * as React from "react"
import { useLocation } from "react-router-dom"
import Background from "../../assets/img/login-bg.png"
import { Location } from "../../react-app-env"
import * as API from "../../utils/Api"
import LoginForm from "./LoginForm"

const Login = () => {
  const [state, setState] = React.useState({
    loading: false,
    error: "",
  })
  const location = useLocation<Location | null>()

  const doLogin = (email: string, password: string, persistLogin: boolean) => {
    setState({
      loading: true,
      error: "",
    })

    API.doLogin(email, password, persistLogin).then(({ data }) => {
      if (!data.success) {
        return setState({
          loading: false,
          error: data.data.message,
        })
      }

      if (location.state?.from?.pathname && location.state?.from?.pathname !== "/login") {
        return (window.location.href = location.state.from.pathname)
      }

      window.location.href = "/"
    })
  }

  return (
    <div className="login" style={{ backgroundImage: "url(" + Background + ")" }}>
      <div className="max-width">
        <div className="wrap">
          <div className="_rlp d-flex jc-space-b">
            <div className="_cllp">
              <LoginForm loading={state.loading} doLogin={doLogin} error={state.error} />
            </div>
            <div className="_cllp">
              <div>
                <h2 className="h2">CREATE A FREE ACCOUNT</h2>
                <div className="_frm">
                  <div className="p">
                    <p>BECOME A TEN CLUB MEMBER OR CREATE A FREE ACCOUNT</p>
                  </div>
                  <a
                    href={process.env.REACT_APP_SITE_URL + "ten-club/sign-up?deep=1"}
                    target="_blank"
                    rel="noreferrer"
                    className="button full yellow"
                    id="LoginButton"
                  >
                    Sign up {">"}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Login
