/* eslint-disable @typescript-eslint/no-empty-function */
import { Song as DspSong } from "../types/get-songs"
import { Playlist } from "./../types/playlist"
import Provider, { SongInfo, Status } from "./Provider"

export default class YoutubeProvider extends Provider {
  providerName = "youtube"
  songInfo = new SongInfo("", "", null, "00:00")
  player: YT.Player | null = null
  errorCallback: (error: { message: string }) => void = () => {}
  statusCallback: (...args: unknown[]) => unknown = () => {}

  constructor(player: YT.Player) {
    super()

    this.player = player
    this.player.addEventListener("onStateChange", (e) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this.songInfo.songTitle = e.target.playerInfo.videoData.title
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this.songInfo.songArtist = e.target.playerInfo.videoData.author
    })
  }

  play(): Promise<void> {
    return new Promise((resolve) => {
      // eslint-disable-next-line no-var
      var interval = setInterval(() => {
        if (!this.player) {
          console.log("Waiting for youtube player")
          return
        }
        this.player?.playVideo()
        if (this.player.getPlayerState() === YT.PlayerState.PLAYING) {
          this.statusCallback(Status.PLAYING)
          resolve()
          clearInterval(interval)
        }
      }, 150)
    })
  }
  pause(): Promise<unknown> {
    this.player?.pauseVideo()
    this.statusCallback(Status.PAUSED)
    return Promise.resolve()
  }
  stop() {
    this.player?.stopVideo()
    this.statusCallback(Status.STOPPED)
  }
  next(): Promise<unknown> {
    this.player?.nextVideo()
    return Promise.resolve()
  }
  previous(): Promise<unknown> {
    this.player?.previousVideo()
    return Promise.resolve()
  }
  seek(_time: number): Promise<unknown> {
    this._warning("seek")
    return Promise.resolve()
  }
  async setPlaylist(playlist: Playlist) {
    this.player?.stopVideo()
    this.statusCallback(Status.LOADING)
    /**
     * for providers if dsp is set, dsp_id (user playlist id) will also be set (corresponding to a playlist with apple/spotify)
     * user playlist flow for youtube dsp is faked and stored in our database
     * so here if !playlist.dsp === undefined then its a real youtube playlist
     * if playlist.dsp === 'youtube' we include playlist.songs[]
     */

    if (playlist.dsp) {
      if (playlist.dsp !== "youtube") {
        return Promise.reject("Invalid playlist")
      }

      const playlistIds =
        (playlist.songs
          .map((x) => x.dsp_song?.dsp_youtube_id)
          .filter((x) => Boolean(x)) as string[]) ?? []

      this?.player?.cuePlaylist(playlistIds)
    }

    if (!playlist.dsp) {
      this?.player?.cuePlaylist({
        listType: "playlist",
        list: playlist.dsp_youtube_id,
      })
    }

    await this.play()

    return Promise.resolve(playlist)
  }
  setSong(song: DspSong) {
    this.player?.cueVideoById(song.dsp_youtube_id)
    return Promise.resolve(song.dsp_youtube_id)
  }
  info(): Promise<SongInfo | null> {
    return Promise.resolve(this.songInfo)
  }
  onSongChange(handler: (...args: unknown[]) => unknown) {
    handler(this.songInfo)
  }

  onError(handler: (...args: unknown[]) => unknown) {
    this.player?.addEventListener("onError", handler)
  }
  onPlayerStatusChange(handler: (...args: unknown[]) => unknown) {
    this.player?.addEventListener("onStateChange", (event) => {
      handler(
        mapStateToApplicationState(
          event.target.getPlayerState() ?? window.YT.PlayerState.UNSTARTED,
        ),
      )
    })
  }
  onSongProgressChange(handler: (...args: unknown[]) => unknown) {
    this._warning("onSongProgressChange")
    handler(null)
  }
  dispose() {
    this.player?.stopVideo()
  }
}

function mapStateToApplicationState(state: YT.PlayerState) {
  switch (state) {
    case window.YT.PlayerState.PAUSED:
      return Status.PAUSED
    case window.YT.PlayerState.PLAYING:
      return Status.PLAYING
    case window.YT.PlayerState.BUFFERING:
      return Status.LOADING
    case window.YT.PlayerState.CUED:
    case window.YT.PlayerState.UNSTARTED:
    case window.YT.PlayerState.ENDED:
      return Status.STOPPED
    default:
      return assertNever(state)
  }
}

export function assertNever<T>(_value: never): T {
  return _value
}

function itemToSongInfo(item: YT.AutoHide) {
  // return new SongInfo(
  //   item.title,
  //   item.artistName,
  //   item.artworkURL,
  //   window.MusicKit.formatMediaTime(item.playbackDuration / 1000, ":"),
  // )
}
