import React, { useEffect, useMemo, useState } from "react"
import RatingsSpriteSheetImage from "../assets/img/pb-rating-sprite.png"
import { SpriteSheet } from "react-spritesheet"

const spritesData = {
  empty: {
    x: 0,
    y: 0,
    height: 70,
    width: 31
  },
  half: {
    x: 0,
    y: 70,
    height: 70,
    width: 31
  },
  full: {
    x: 0,
    y: 140,
    height: 70,
    width: 31
  }
}

const Rating = ({ currentRating, maxRating, onRate, label, editable = true }) => {
  const [rating, setRating] = useState(0)
  const [displayRating, setDisplayRating] = useState(0)
  const numberToRender = maxRating ?? 5

  const [values, setValues] = useState([])

  useEffect(() => {
    setRating(currentRating)
    setDisplayRating(currentRating)
  }, [currentRating])

  useMemo(() => {
    const hasHalf = displayRating % 1 > 0
    const numberOfFull = displayRating - (hasHalf ? 0.5 : 0)
    const numberOfEmpty = numberToRender - Math.ceil(displayRating)

    let v = []

    for (let i = 0; i < numberOfFull; i = i + 1) {
      v.push("full")
    }

    if (hasHalf) {
      v.push("half")
    }

    for (let j = 0; j < numberOfEmpty; j = j + 1) {
      v.push("empty")
    }

    setValues(v)
  }, [displayRating, numberToRender])

  const updateRating = (r) => {
    setRating(r)
    if (onRate) {
      onRate(r)
    }
  }

  return (
    <div
      className={`rating ${editable ? "enabled" : ""}`}
      title={`${displayRating} of ${numberToRender}`}
    >
      <div className="rating-indicators">
        {values.map((value, i) => (
          <React.Fragment key={i}>
            {editable && (
              <button
                className="rating-indicator"
                onTouchStart={() => updateRating(i + 1)}
                onMouseMoveCapture={(e) => {
                  const rect = e.target.getBoundingClientRect()
                  // x position within the element.
                  const x = e.clientX - rect.left

                  let amount = i + 1
                  // if less than half way through, remove the half
                  if (x < rect.width / 2) {
                    amount = amount - 0.5
                  }

                  setDisplayRating(amount)
                }}
                onMouseOut={() => setDisplayRating(rating)}
                onClick={() => updateRating(displayRating)}
              >
                <SpriteSheet filename={RatingsSpriteSheetImage} data={spritesData} sprite={value} />
              </button>
            )}

            {!editable && (
              <span className="rating-indicator">
                <SpriteSheet filename={RatingsSpriteSheetImage} data={spritesData} sprite={value} />
              </span>
            )}
          </React.Fragment>
        ))}
      </div>
      {label && <span className="rating-label">{label}</span>}
    </div>
  )
}

export default Rating
