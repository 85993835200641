import React, { Component } from "react"
import Banner from "../sections/Banner"
import MyShowsSection from "../sections/MyShowsSection"
import PlayList from "../sections/PlayList"

export default class MyPlayLists extends Component {
  render() {
    return (
      <div>
        <PlayList />
        <Banner />
        <MyShowsSection />
      </div>
    )
  }
}
