/* eslint-disable no-param-reassign */
import React, { Component, useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { AppContext } from "../../contexts/AppContext"
import { MusicPlayerContextConsumer } from "../../contexts/MusicPlayerContext"
import DeleteIcon from "../../svgs/DeleteIcon"
import Marker from "../../svgs/Marker"
import Play from "../../svgs/Play"
import Vector from "../../svgs/Vector"

const FilterSelect = ({ label, value, name, options, changeFilter }) => {
  const [fieldLabel, setFieldLabel] = useState(label)
  useEffect(() => {
    if (!value) {
      setFieldLabel(label)
    }
  }, [label, value])

  return (
    <div className={"filterSelect" + (value ? " filled" : "")}>
      <label>{fieldLabel}</label>
      <select
        name={name}
        value={value}
        onChange={(e) => {
          setFieldLabel(e?.target?.options[e.target.selectedIndex]?.innerHTML || label)
          changeFilter(e)
        }}
      >
        <option value="">{label}</option>
        {options.map((option) => (
          <option value={option} key={option}>
            {option}
          </option>
        ))}
      </select>
    </div>
  )
}

export default class AllShows extends Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: true,
      retries: 0,
      filteredShows: [],
      visibleShows: [],

      filters: {
        search: "",
        year: "",
        country: "",
        state: "",
      },

      filterOptions: {
        years: [],
        countries: [],
        states: [],
      },
    }

    this.changeFilter = this.changeFilter.bind(this)
    this.clearFilters = this.clearFilters.bind(this)
  }

  componentDidMount() {
    if (this.state.visibleShows.length === 0) {
      this.fetchVisibleShows()
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.visibleShows.length === 0 && this.state.loading && this.state.retries <= 3) {
      this.fetchVisibleShows()
    }
  }

  async fetchVisibleShows() {
    try {
      const myShows = await this.context.actions.loadMyShows()

      if (typeof myShows !== "undefined" && myShows.length === 0) {
        this.setState({
          retries: this.state.retries + 1,
        })
      }

      this.setState({
        loading: false,
        visibleShows: this.filterShows(
          this.state.filters,
          this.context.state.shows,
          this.context.state.songs,
          myShows,
          this.props.myShows,
        ),
        filterOptions: this.buildFilterOptions(this.context.state.shows),
      })
    } catch (err) {
      process.env.NODE_ENV === "development" &&
        console.warn("AllShows: fetchVisibleShows: error: ", err)
    }
  }

  filterShows(filters, shows = [], songs = [], myshows = [], onlyMyShows = false) {
    if (onlyMyShows) {
      if (myshows.length < 1) {
        return []
      }

      shows = shows.filter((show) => myshows.indexOf(show.id) !== -1)
    }

    if (filters.search) {
      const term = filters.search.toLowerCase()

      const showIds = songs
        .filter((song) => {
          return song.name.toLowerCase().indexOf(term) !== -1
        })
        .map((song) => {
          return song.show_id
        })

      shows = shows.filter((show) => {
        return (
          show.name.toLowerCase().indexOf(term) !== -1 ||
          show.date.indexOf(filters.search) !== -1 ||
          showIds.indexOf(show.id) !== -1
        )
      })
    }

    if (filters.year) {
      shows = shows.filter((show) => show.date.substring(0, 4) === filters.year)
    }

    if (filters.country) {
      shows = shows.filter((show) => show.country === filters.country)
    }

    if (filters.state) {
      shows = shows.filter((show) => show.city_state === filters.state)
    }

    return shows
  }

  buildFilterOptions(shows = []) {
    let years = []
    let countries = []
    let states = []
    shows.forEach((show) => {
      const year = show.date.substring(0, 4)
      if (years.indexOf(year) === -1) {
        years.push(year)
      }

      if (countries.indexOf(show.country) === -1) {
        countries.push(show.country)
      }

      if (states.indexOf(show.city_state) === -1) {
        states.push(show.city_state)
      }
    })

    return {
      years,
      countries,
      states,
    }
  }

  changeFilter(e) {
    const filters = {
      ...this.state.filters,
      [e.target.name]: e.target.value,
    }

    this.setState({
      visibleShows: this.filterShows(
        filters,
        this.context.state.shows,
        this.context.state.songs,
        this.context.state.myShows,
        this.props.myShows ?? false,
      ),
      filters: {
        ...this.state.filters,
        [e.target.name]: e.target.value,
      },
    })
  }

  clearFilters() {
    const filters = {
      ...this.state.filters,
      year: "",
      country: "",
      state: "",
    }

    this.setState({
      visibleShows: this.filterShows(
        filters,
        this.context.state.shows,
        this.context.state.songs,
        this.context.state.myShows,
        this.props.myShows ?? false,
      ),
      filters: {
        ...this.state.filters,
        year: "",
        country: "",
        state: "",
      },
    })
  }

  render() {
    return (
      <div className={"myShows " + (this.props.isYellow ? " _ylwsh " : "")}>
        <div className="max-width">
          <div className="wrap">
            <div className="head d-flex align-fe">
              <div>
                <h2 className="h2">{this.props.title ?? "All Shows"}</h2>
              </div>
              <div className="search">
                <div className="d-flex align-center">
                  <label htmlFor="_searchInMyShows" className="fa fa-search" />
                  <input
                    type="search"
                    name="search"
                    id="_searchInMyShows"
                    placeholder="SEARCH CITY, YEAR, TOUR OR SONG"
                    value={this.state.filters.search}
                    onChange={this.changeFilter}
                  />
                </div>
              </div>
              <div className="flex-1 d-flex">
                <span className="filter">FILTER</span>
                <div className="ul d-flex jc-space-b flex-1">
                  <FilterSelect
                    label="Year"
                    name="year"
                    options={this.state.filterOptions.years}
                    value={this.state.filters.year}
                    changeFilter={this.changeFilter}
                  />
                  <FilterSelect
                    label="Country"
                    name="country"
                    options={this.state.filterOptions.countries}
                    value={this.state.filters.country}
                    changeFilter={this.changeFilter}
                  />
                  <FilterSelect
                    label="City, State"
                    name="state"
                    options={this.state.filterOptions.states}
                    value={this.state.filters.state}
                    changeFilter={this.changeFilter}
                  />
                </div>
              </div>
              {(this.state.filters.year ||
                this.state.filters.country ||
                this.state.filters.state) && (
                <button className="transparentBtn clear-filter" onClick={() => this.clearFilters()}>
                  Clear Filters
                </button>
              )}
            </div>
          </div>
        </div>
        <div className="_bgwh">
          <div className="max-width">
            <div className="wrap">
              <table className="table w100">
                <thead>
                  <tr>
                    <th>VENUE</th>
                    <th>DATE</th>
                    <th>COUNTRY</th>
                    <th>CITY, ST</th>
                    <th>ADD TO MY SHOWS</th>
                  </tr>
                </thead>
                <tbody>
                  <MusicPlayerContextConsumer>
                    {(context) =>
                      this.state.visibleShows.map((show) => (
                        <tr key={show.id}>
                          <td>
                            <Link to={"/shows/" + show.id}>{show.name}</Link>{" "}
                            <button
                              className="transparentBtn play"
                              onClick={() => context.playPlaylist(show)}
                            >
                              <Play />
                            </button>
                          </td>
                          <td className="_visible-sm">
                            <button className="transparentBtn">
                              <Play />
                            </button>
                          </td>
                          <td className="fw-bold-sm">{show.date_pretty}</td>
                          <td className="_hidden-sm">{show.country}</td>
                          <td className="_hidden-sm">
                            {show.city}
                            {show.state ? ", " + show.state : ""}
                          </td>
                          <td className="_visible-sm">
                            {show.city}
                            {show.state ? " " + show.state : ""}, {show.country}
                          </td>
                          <td align="center">
                            {this.context.state.myShows.indexOf(parseInt(show.id)) !== -1 ? (
                              <>
                                <button
                                  className="addToPlayList _visible-sm"
                                  onClick={() => this.context.actions.removeFromMyShows(show.id)}
                                >
                                  {" "}
                                  - REMOVE FROM MY SHOWS
                                </button>
                                <button
                                  className="transparentBtn _hidden-sm"
                                  onClick={() => this.context.actions.removeFromMyShows(show.id)}
                                >
                                  <DeleteIcon />
                                </button>
                              </>
                            ) : (
                              <>
                                <button
                                  className="addToPlayList _visible-sm"
                                  onClick={() => this.context.actions.addToMyShows(show.id)}
                                >
                                  {" "}
                                  + ADD TO MY SHOWS
                                </button>
                                <button
                                  className="transparentBtn _hidden-sm"
                                  onClick={() => this.context.actions.addToMyShows(show.id)}
                                >
                                  <Vector />
                                </button>
                              </>
                            )}
                          </td>
                          <td className="_visible-sm">
                            <Marker />
                          </td>
                        </tr>
                      ))
                    }
                  </MusicPlayerContextConsumer>
                  {!this.context.state.loading && this.state.visibleShows.length === 0 && (
                    <tr>
                      <td colSpan="8" align="center">
                        <h3>There are no shows matching your filter.</h3>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

AllShows.contextType = AppContext
