import React, { Component } from "react"
import { Link } from "react-router-dom"
import Skeleton, { SkeletonTheme } from "react-loading-skeleton"
import Play from "../../svgs/Play"
import AddToList from "../../svgs/AddToList"
import { MusicPlayerContextConsumer } from "../../contexts/MusicPlayerContext"

import { AppConsumer } from "../../contexts/AppContext"
import { ManagePlaylistsConsumer } from "../../contexts/ManagePlaylistsContext"
import NotFound from "../../NotFound"

export default class SinglePlaylist extends Component {
  render() {
    if (!this.props.loading && !this.props.playlist) {
      return <NotFound />
    }

    return (
      <ManagePlaylistsConsumer>
        {(managePlaylistsContext) => (
          <div className="single-playlist">
            <div className="max-width">
              <div className="wrap">
                <div className="singlePlayListSlider">
                  <div className="item">
                    <MusicPlayerContextConsumer>
                      {(musicPlayerContext) => (
                        <>
                          <h2 className="h2 center">
                            {this.props.loading ? "Loading" : this.props.playlist.name}
                            {!this.props.loading &&
                              musicPlayerContext.canPlayPlaylist(this.props.playlist) && (
                                <button
                                  className="transparentBtn play"
                                  onClick={() =>
                                    musicPlayerContext.playPlaylist(this.props.playlist)
                                  }
                                >
                                  <Play />
                                </button>
                              )}
                            <span>
                              {!this.props.loading
                                ? this.props.playlist.songs.length + " songs"
                                : ""}
                            </span>
                          </h2>
                          <SkeletonTheme color="#000" highlightColor="#444">
                            <table className="table-colored" width="100%">
                              <tbody>
                                {this.props.loading && (
                                  <tr>
                                    <td>
                                      <Skeleton width="100%" />
                                    </td>
                                  </tr>
                                )}

                                {!this.props.loading && this.props.playlist.songs.length === 0 && (
                                  <tr>
                                    <td
                                      style={{
                                        textAlign: "center",
                                      }}
                                    >
                                      This playlist has no songs yet.
                                    </td>
                                  </tr>
                                )}

                                {!this.props.loading && this.props.playlist.songs && (
                                  <AppConsumer>
                                    {(appContext) =>
                                      this.props.playlist.songs.map((playlistSong) => {
                                        const song =
                                          appContext.state.songsByDspId[playlistSong.dsp_song_id]
                                        if (typeof song === "undefined") {
                                          return true
                                        }

                                        const show = appContext.state.showsById[song.show_id]

                                        return (
                                          <tr key={playlistSong.id}>
                                            <td>
                                              <span>{song.name}</span>
                                              <span>
                                                <button
                                                  className="transparentBtn play"
                                                  onClick={() => musicPlayerContext.playSong(song)}
                                                >
                                                  <Play />
                                                </button>
                                                {this.props.featured && (
                                                  <button
                                                    className="transparentBtn play"
                                                    onClick={() =>
                                                      managePlaylistsContext.actions.addSongs([
                                                        song,
                                                      ])
                                                    }
                                                  >
                                                    <AddToList />
                                                  </button>
                                                )}
                                              </span>
                                            </td>
                                            <td>{show.name}</td>
                                            <td>{show.date_pretty}</td>
                                            <td>
                                              <Link
                                                to={`/shows/${song.show_id}/songs/${song.song_id}`}
                                              >
                                                SONG DETAILS {">"}
                                              </Link>
                                            </td>
                                          </tr>
                                        )
                                      })
                                    }
                                  </AppConsumer>
                                )}
                              </tbody>
                            </table>
                          </SkeletonTheme>
                        </>
                      )}
                    </MusicPlayerContextConsumer>
                  </div>
                  {!this.props.loading && !this.props.featured && (
                    <div className="delete-playlist">
                      <button
                        type="button"
                        onClick={() => {
                          managePlaylistsContext.actions
                            .deletePlaylist(this.props.playlist)
                            .then(() => this.props.history.push("/my-playlists"))
                        }}
                      >
                        Delete Playlist
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </ManagePlaylistsConsumer>
    )
  }
}
