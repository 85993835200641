import React from "react"
import * as Api from "../utils/Api"
import { AppContext } from "./AppContext"
import { MusicPlayerContextConsumer } from "./MusicPlayerContext"

export const ManagePlaylistsContext = React.createContext({})
export const ManagePlaylistsConsumer = ManagePlaylistsContext.Consumer

export class ManagePlaylistsProvider extends React.Component {
  initialState = {
    visible: false,

    songs: [],
    currentDsp: null,

    playlists: [],
    playlistsLoading: false,
    playlistsLoaded: false,
  }

  constructor(props) {
    super(props)

    this.state = this.initialState

    this.toggleModal = this.toggleModal.bind(this)
    this.loadPlaylists = this.loadPlaylists.bind(this)
    this.addSongs = this.addSongs.bind(this)
    this.addSongsToPlaylist = this.addSongsToPlaylist.bind(this)
    this.deletePlaylist = this.deletePlaylist.bind(this)
  }

  toggleModal() {
    this.setState({
      visible: !this.state.visible,
    })
  }

  loadPlaylists() {

    if (this.state.playlistsLoaded || this.state.playlistsLoading) {
      return Promise.resolve(this.state.playlists)
    }

    this.setState({
      playlistsLoading: true,
    })

    return new Promise((resolve) => {
      Api.getMyPlaylists().then(({ data: { data } }) => {
        this.setState({
          playlistsLoaded: true,
          playlistsLoading: false,
          playlists: data.playlists,
        })

        resolve()
      })
    })
  }

  addPlaylist(title, dsp, dsp_token) {

    return new Promise((resolve, reject) => {
      Api.createPlaylist(title, dsp, dsp_token)
        .then(({ data }) => {
          if (!data.success) {
            alert("There was a problem adding your playlist. Please refresh and try again.") // eslint-disable-line no-alert
            reject()
            return
          }

          this.addSongsToPlaylist(data.data.playlist.id, dsp, dsp_token)
            .then(() => {
              this.setState({
                playlistsLoaded: false,
              })

              resolve()
            })
            .catch(reject)
        })
        .catch((e) => {
          alert("There was a problem adding your playlist. Please refresh and try again.") // eslint-disable-line no-alert
          reject(e)
        })
    })
  }

  addSongsToPlaylist(id, dsp, dsp_token) {

    return new Promise((resolve, reject) => {
      Api.addSongsToPlaylist(id, dsp, dsp_token, this.state.songs)
        .then((response) => {
          if (!response.data.success) {
            alert(
              "There was a problem adding these songs to your playlist. Please refresh and try again.",
            ) // eslint-disable-line no-alert
            reject()
            return
          }

          this.setState({
            playlistsLoaded: false,
            playlistsLoading: false,
          })

          resolve()
        })
        .catch((e) => {
          alert(
            "There was a problem adding these songs to your playlist. Please refresh and try again.",
          ) // eslint-disable-line no-alert
          reject(e)
        })
    })
  }

  addSongs(songs, dsp) {

    this.loadPlaylists()

    this.setState({
      visible: true,
      currentDsp: dsp,
      songs,
    })
  }

  deletePlaylist(playlist) {
    if (!window.confirm("Are you sure you want to delete this playlist?")) {
      // eslint-disable-line no-alert
      return false
    }

    return Api.deletePlaylist(playlist.id).then(() => {
      this.setState({
        playlistsLoaded: false,
        playlistsLoading: false,
      })
    })
  }

  render() {
    return (
      <AppContext.Consumer>
        {(appcontext) => (
          <MusicPlayerContextConsumer>
            {(context) => (
              <ManagePlaylistsContext.Provider
                value={{
                  state: this.state,
                  actions: {
                    toggleModal: appcontext.actions.withAuthCheck(this.toggleModal),
                    loadPlaylists: appcontext.actions.withAuthCheck(this.loadPlaylists),
                    addSongs: appcontext.actions.withAuthCheck((songs) =>
                      context.authorize((provider) => this.addSongs(songs, provider?.providerName)), true),
                    addPlaylist: appcontext.actions.withAuthCheck((title) =>
                      context.authorize((provider) => {
                        this.addPlaylist(
                          title,
                          provider?.providerName,
                          context.getService().getAccessToken(),
                        )
                      }), true),
                    addSongsToPlaylist: appcontext.actions.withAuthCheck((id) =>
                      context.authorize((provider) => {
                        this.addSongsToPlaylist(
                          id,
                          provider?.providerName,
                          context.getService().getAccessToken(),
                        )
                      }), true),
                    deletePlaylist: appcontext.actions.withAuthCheck(this.deletePlaylist, true),
                  },
                }}
              >
                <div
                  id="youtube-player-dom-id"
                  style={{ position: "absolute", left: -500000, height: 0, width: 0 }}
                />
                {this.props.children}
              </ManagePlaylistsContext.Provider>
            )}
          </MusicPlayerContextConsumer>
        )}
      </AppContext.Consumer>
    )
  }
}
