import AppleProvider from "../providers/AppleProvider"
// import Cookies from 'js-cookie'
import { getAppleMusicToken } from "../utils/Api"

export default class AppleHelper {
  instance: MusicKit.MusicKitInstance | null = null

  constructor() {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this // eslint-disable-line consistent-this
    getAppleMusicToken().then(({ data: { data } }) => {
      window?.MusicKit?.configure({
        developerToken: data.token,
        app: {
          name: "Pearl Jam Deep",
          build: "1",
        },
      })

      self.instance = window.MusicKit?.getInstance()
    })
  }

  getInstance() {
    return this.instance
  }

  getProvider() {
    if (this.instance === null) throw Error("Instance is null")

    return new AppleProvider(this.instance)
  }

  isAuthorized() {
    return this.instance?.isAuthorized
  }

  authorize() {
    return this.instance?.authorize()
  }

  getAccessToken() {
    return this.instance?.musicUserToken
  }
}
