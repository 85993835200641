import * as React from "react"
import AllShowsSection from "../sections/AllShows"

const AllShows = () => {
  return (
    <div>
      <AllShowsSection isYellow={true} />
    </div>
  )
}

export default AllShows
