import React, { Component } from "react"
import { Link } from "react-router-dom"
import AddToList from "../../svgs/AddToList"
import Play from "../../svgs/Play"
import { AppConsumer } from "../../contexts/AppContext"
import { ManagePlaylistsConsumer } from "../../contexts/ManagePlaylistsContext"
import { MusicPlayerContextConsumer } from "../../contexts/MusicPlayerContext"

import * as Api from "../../utils/Api"

export default class SetList extends Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: true,
      sets: [],
    }
  }

  componentDidMount() {
    Api.getShowSetlist(this.props.show.id).then(({ data: { data } }) => {
      this.setState({
        loading: false,
        sets: data.sets,
      })
    })
  }

  render() {
    return (
      <div className="set-list">
        <div className="max-width">
          <div className="wrap">
            <h2 className="entry-title">Set List</h2>

            <div className="lists">
              {this.state.loading && (
                <div className="list">
                  <div className="list-title">Loading...</div>
                </div>
              )}

              {!this.state.loading && (
                <ManagePlaylistsConsumer>
                  {(managePlaylistsContext) => (
                    <MusicPlayerContextConsumer>
                      {(musicPlayerContext) => (
                        <AppConsumer>
                          {(context) => {
                            return this.state.sets.map((set) => (
                              <div className="list" key={set.id}>
                                <button className="list-title">{set.name}</button>
                                <ul>
                                  {set.songs.map((songId, index) => {
                                    let song
                                    try {
                                      song =
                                        context.state.songsByShowId[parseInt(this.props.show.id)][
                                          parseInt(songId)
                                        ]

                                      if (!song) {
                                        return null
                                      }
                                    } catch (e) {
                                      return null
                                    }

                                    const songNumber = index + 1 < 10 ? `0${index + 1}` : index + 1

                                    return (
                                      <li key={songId}>
                                        <button
                                          className="transparentBtn playB"
                                          onClick={() => musicPlayerContext.playSong(song)}
                                        >
                                          <Play />{" "}
                                        </button>
                                        <button
                                          className="transparentBtn addto"
                                          onClick={() =>
                                            managePlaylistsContext.actions.addSongs([song])
                                          }
                                        >
                                          <AddToList />{" "}
                                        </button>
                                        <Link to={`/shows/${this.props.show.id}/songs/${songId}`}>
                                          {songNumber}. {song.name}
                                        </Link>
                                      </li>
                                    )
                                  })}
                                </ul>
                              </div>
                            ))
                          }}
                        </AppConsumer>
                      )}
                    </MusicPlayerContextConsumer>
                  )}
                </ManagePlaylistsConsumer>
              )}
            </div>

            <div className="clear" />
          </div>
        </div>
      </div>
    )
  }
}
