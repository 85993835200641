import React, { Component } from "react"
import { Link } from "react-router-dom"

class NotFound extends Component {
  render() {
    return (
      <div className="wrap not-found">
        <div className="_404">404</div>
        <div className="text-center notfound">
          Page Not Found
          <br />
          <span>
            Click <Link to="/">Here</Link> to return back to the Homepage
          </span>
        </div>
      </div>
    )
  }
}

export default NotFound
