import React, { useContext, useEffect, useState } from "react"
import { Link } from "react-router-dom"
import Background from "../../assets/img/playlist-Background.jpg"
import { useAppContext } from "../../contexts/AppContext"
import { MusicPlayerContext } from "../../contexts/MusicPlayerContext"
import CD from "../../svgs/CD"
import DownloadIcon from "../../svgs/DownloadIcon"
import Play from "../../svgs/Play"
import { rateShow } from "../../utils/Api"
import Rating from "../Rating.js"



const SHOW_URL_PATH = "https://downloads-pearljam-com.s3.amazonaws.com/img/show-poster"

const ShowDetails = ({ show }) => {
  const { state, actions } = useAppContext()
  const musicPlayer = useContext(MusicPlayerContext)
  const [isInMyShows, setIsInMyShows] = useState(false)
  const [myRating, setMyRating] = useState(0)
  const [averageRating, setAverageRating] = useState(show.rating)

  useEffect(() => {
    setAverageRating(show.rating)
  }, [show.rating])

  useEffect(() => {
    actions.loadMyShows()
  }, [actions])

  useEffect(() => {
    if (state.myShowsLoaded) {
      setIsInMyShows(state.myShows.indexOf(show.id) > -1)
      setMyRating(state.myShowRatings?.[show.id])
    }
  }, [state.myShows, state.myShowRatings, state.myShowsLoaded, show.id])

  const handleRate = (rating) => {
    rateShow(show.id, rating).then(({ data }) => {
      const {
        data: {
          data: { rating: updatedRating },
        },
      } = data
      setAverageRating(updatedRating)
      actions.updateShowRating(parseInt(show.id), updatedRating, rating)
    })
  }

  return (
    <div className="show-details" style={{ backgroundImage: "url(" + Background + ")" }}>
      <div className="max-width">
        <div className="wrap">
          <div className="_sdrw d-flex align-center jc-space-b">
            <div className="_sdcl">
              <img src={`${SHOW_URL_PATH}/${show.poster_file}`} alt={show.venue_name} />
            </div>

            <div className="_sdcl">
              <Link to="/shows" className="goBack">
                {"<"} BACK TO ALL SHOWS
              </Link>

              <div className="date">
                {show.date_pretty}
                {show.category && (
                  <>
                    {" "}
                    | <span>{show.category}</span>
                  </>
                )}
              </div>
              <div className="entry-name">
                {show.venue_name}{" "}
                <span>
                  {show.city_state}, {show.country}
                </span>
              </div>

              <button
                className="transparentBtn play"
                onClick={() => musicPlayer.playPlaylist(show)}
              >
                <Play />
              </button>

              <Rating label="Average" currentRating={averageRating} editable={false} />
              <Rating
                label="My Rating"
                currentRating={myRating ?? 0}
                onRate={actions.withAuthCheck(handleRate, true)}
              />

              <div className="buttons">
                {show.cd && (
                  <a href={show.cd} target="_blank" className="button gris" rel="noreferrer">
                    <CD /> CD
                  </a>
                )}
                {show.digital && (
                  <a href={show.digital} target="_blank" className="button gris" rel="noreferrer">
                    <DownloadIcon /> DIGITAL
                  </a>
                )}
              </div>

              {state.myShowsLoaded && (
                <>
                  {!isInMyShows && (
                    <button
                      onClick={() => actions.addToMyShows(show.id)}
                      className="button full text-center"
                    >
                      + ADD TO MY SHOWS
                    </button>
                  )}
                  {isInMyShows && (
                    <button
                      onClick={() => actions.removeFromMyShows(show.id)}
                      className="button full text-center"
                    >
                      - REMOVE FROM MY SHOWS
                    </button>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ShowDetails
