import React, { Component } from "react"
import { Link, NavLink } from "react-router-dom"
import Logo from "../../assets/img/Deep-Logo-beta.png"
import UserIcon from "../../assets/img/user-icon.svg"
import { AppConsumer } from "../../contexts/AppContext"

class Header extends Component {
  constructor(props) {
    super(props)
    this.navmenu = React.createRef()
  }

  HandleToggle(e) {
    e.preventDefault()
    this.navmenu.current.classList.toggle("on")
  }
  render() {
    return (
      <AppConsumer>
        {(context) => (
          <header className="header" id="header">
            <div className="max-width">
              <div className="wrap">
                <div className="navbar d-flex jc-space-b align-center">
                  <div className="brand">
                    <Link to="/">
                      <img src={Logo} alt="Deep Logo" />
                    </Link>
                  </div>
                  <>
                    <div className="navmenu" ref={this.navmenu}>
                      <div className="btnToggle" onClick={this.HandleToggle.bind(this)}>
                        <span />
                        <span />
                        <span />
                      </div>
                      <div className="menu">
                        <ul className="d-flex">
                          <li>
                            <NavLink exact={true} activeClassName="active" to="/">
                              FEATURED
                            </NavLink>
                          </li>
                          <li>
                            <NavLink exact={true} activeClassName="active" to="/shows">
                              ALL SHOWS
                            </NavLink>
                          </li>
                          <li>
                            <NavLink exact={true} activeClassName="active" to="/my-shows">
                              MY SHOWS
                            </NavLink>
                          </li>
                          <li>
                            <NavLink exact={true} activeClassName="active" to="/my-playlists">
                              MY PLAYLISTS
                            </NavLink>
                          </li>
                          <li>
                            <a
                              target="_blank"
                              rel="noreferrer nofollow"
                              href="https://generator.pearljam.com/"
                            >
                              Setlist Generator
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="LoginButton">
                      {context.state.isAuthenticated ? (
                        <a href={process.env.REACT_APP_SITE_URL + "ten-club/log-out"} className="">
                          <img src={UserIcon} alt="login icon" />
                          Logout
                        </a>
                      ) : (
                        <a href="/login" className="">
                          <img src={UserIcon} alt="login icon" />
                          Login
                        </a>
                      )}
                    </div>
                  </>
                </div>
              </div>
            </div>
          </header>
        )}
      </AppConsumer>
    )
  }
}
export default Header
