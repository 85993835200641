import React, { Component } from "react"

export default class Amazon extends Component {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="135"
        height="40"
        viewBox="0 0 135 40"
        fill="none"
      >
        <g clipPath="url(#clip0)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M83.7513 31.2812C75.9062 36.9666 64.5353 40 54.7451 40C41.0177 40 28.6595 35.0079 19.31 26.7052C18.5755 26.0523 19.2336 25.1625 20.1151 25.671C30.205 31.443 42.6807 34.9155 55.5678 34.9155C64.2591 34.9155 73.8201 33.1475 82.6113 29.4786C83.9394 28.9239 85.05 30.3337 83.7513 31.2812Z"
            fill="white"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M87.0128 27.6123C86.0138 26.3527 80.3842 27.0172 77.8573 27.3118C77.0875 27.4043 76.97 26.7456 77.6634 26.2718C82.1471 23.1691 89.5045 24.0647 90.3624 25.1047C91.2204 26.1505 90.1391 33.4017 85.9257 36.8626C85.2793 37.3942 84.6623 37.111 84.9502 36.4062C85.8963 34.0835 88.0177 28.8776 87.0128 27.6123Z"
            fill="white"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M78.0336 4.36806V1.35203C78.0336 0.895581 78.3862 0.589355 78.8093 0.589355H92.5426C92.9833 0.589355 93.3359 0.901359 93.3359 1.35203V3.93472C93.33 4.36806 92.9598 4.93429 92.3016 5.82985L85.1852 15.8197C87.8296 15.7562 90.621 16.1433 93.0186 17.4722C93.5592 17.7726 93.7061 18.2118 93.7472 18.6451V21.8634C93.7472 22.3025 93.2536 22.8167 92.7365 22.5509C88.5113 20.3727 82.8993 20.1358 78.2275 22.574C77.7515 22.8283 77.252 22.3198 77.252 21.8807V18.8242C77.252 18.3331 77.2579 17.4953 77.7574 16.75L86.0021 5.12496H78.8269C78.3862 5.12496 78.0336 4.81873 78.0336 4.36806Z"
            fill="white"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M27.9367 23.1865H23.7586C23.359 23.1576 23.0416 22.8629 23.0123 22.4874V1.40406C23.0123 0.982282 23.3707 0.647165 23.8173 0.647165H27.7134C28.1189 0.664487 28.4421 0.970723 28.4715 1.35206V4.10809H28.5479C29.5645 1.44451 31.4744 0.202271 34.0483 0.202271C36.6633 0.202271 38.2969 1.44451 39.4722 4.10809C40.483 1.44451 42.7807 0.202271 45.2429 0.202271C46.9941 0.202271 48.9098 0.912944 50.0792 2.50763C51.4014 4.28142 51.1311 6.85834 51.1311 9.11747L51.1253 22.4238C51.1253 22.8456 50.7668 23.1865 50.3202 23.1865H46.1479C45.7307 23.1576 45.3957 22.8283 45.3957 22.4238V11.2495C45.3957 10.3597 45.478 8.14102 45.2782 7.29745C44.9667 5.88189 44.0324 5.48321 42.8218 5.48321C41.8111 5.48321 40.7533 6.14766 40.3243 7.21079C39.8953 8.27391 39.9365 10.0535 39.9365 11.2495V22.4238C39.9365 22.8456 39.578 23.1865 39.1314 23.1865H34.9591C34.536 23.1576 34.2069 22.8283 34.2069 22.4238L34.201 11.2495C34.201 8.89792 34.5948 5.43699 31.6272 5.43699C28.6243 5.43699 28.7418 8.81124 28.7418 11.2495V22.4238C28.7418 22.8456 28.3833 23.1865 27.9367 23.1865"
            fill="white"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M105.159 0.202271C111.359 0.202271 114.714 5.43699 114.714 12.0931C114.714 18.5238 111.006 23.6256 105.159 23.6256C99.0713 23.6256 95.7569 18.3909 95.7569 11.8677C95.7569 5.3041 99.1124 0.202271 105.159 0.202271ZM105.195 4.50676C102.115 4.50676 101.921 8.63213 101.921 11.2033C101.921 13.7802 101.88 19.2807 105.159 19.2807C108.397 19.2807 108.55 14.8433 108.55 12.1393C108.55 10.3597 108.474 8.23347 107.927 6.54633C107.457 5.07877 106.523 4.50676 105.195 4.50676Z"
            fill="white"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M122.753 23.1865H118.593C118.176 23.1576 117.841 22.8283 117.841 22.4238L117.835 1.33473C117.87 0.947611 118.217 0.647165 118.64 0.647165H122.513C122.877 0.664487 123.177 0.907168 123.259 1.2365V4.46054H123.335C124.505 1.5774 126.144 0.202271 129.03 0.202271C130.904 0.202271 132.732 0.866723 133.907 2.68674C135 4.37387 135 7.21079 135 9.25036V22.5221C134.953 22.8918 134.606 23.1865 134.195 23.1865H130.005C129.623 23.1576 129.306 22.8803 129.265 22.5221V11.0704C129.265 8.76503 129.535 5.39077 126.65 5.39077C125.633 5.39077 124.699 6.061 124.234 7.0779C123.647 8.36636 123.57 9.64904 123.57 11.0704V22.4238C123.564 22.8456 123.2 23.1865 122.753 23.1865"
            fill="white"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M67.0857 13.1158C67.0857 14.7162 67.1269 16.0509 66.3042 17.4722C65.6401 18.6278 64.5824 19.3385 63.413 19.3385C61.8087 19.3385 60.8684 18.1367 60.8684 16.3629C60.8684 12.8615 64.0594 12.226 67.0857 12.226V13.1158ZM71.2992 23.1287C71.023 23.3714 70.6234 23.3887 70.3119 23.227C68.9251 22.0945 68.6724 21.5687 67.9143 20.4883C65.6225 22.7879 63.9947 23.4754 61.0271 23.4754C57.5071 23.4754 54.7746 21.3434 54.7746 17.0736C54.7746 13.7398 56.608 11.4691 59.2289 10.3597C61.4972 9.37749 64.6646 9.20416 67.0857 8.9326V8.40104C67.0857 7.42458 67.1621 6.26901 66.5745 5.42545C66.0691 4.66855 65.0936 4.35655 64.2298 4.35655C62.6373 4.35655 61.221 5.15967 60.8743 6.82369C60.8038 7.19347 60.5276 7.55747 60.1456 7.57481L56.0968 7.14724C55.7559 7.07214 55.374 6.80057 55.4739 6.28635C56.4023 1.45608 60.8449 6.10352e-05 64.8174 6.10352e-05C66.8507 6.10352e-05 69.5069 0.531623 71.1111 2.04542C73.1444 3.91166 72.9505 6.40191 72.9505 9.11171V15.5136C72.9505 17.4376 73.7614 18.2811 74.5254 19.3212C74.7898 19.6909 74.8486 20.1358 74.5077 20.4132C73.6556 21.1123 72.1395 22.4123 71.305 23.1403L71.2992 23.1287"
            fill="white"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.3112 13.1159C12.3112 14.7164 12.3523 16.0511 11.5296 17.4724C10.8656 18.628 9.81369 19.3387 8.6384 19.3387C7.03412 19.3387 6.09977 18.1369 6.09977 16.3631C6.09977 12.8617 9.29068 12.2262 12.3112 12.2262V13.1159ZM16.5246 23.1289C16.2484 23.3716 15.8488 23.3889 15.5374 23.2272C14.1505 22.0947 13.9037 21.5689 13.1398 20.4885C10.8479 22.788 9.22605 23.4756 6.25255 23.4756C2.73842 23.4756 -3.8147e-06 21.3436 -3.8147e-06 17.0738C-3.8147e-06 13.7399 1.83933 11.4693 4.45435 10.3599C6.72267 9.37767 9.89008 9.20434 12.3112 8.93278V8.40122C12.3112 7.42476 12.3876 6.2692 11.8058 5.42564C11.2946 4.66874 10.3191 4.35673 9.4611 4.35673C7.86858 4.35673 6.44648 5.15985 6.09977 6.82387C6.02925 7.19365 5.75305 7.55766 5.37696 7.57499L1.3222 7.14743C0.981365 7.07232 0.605271 6.80076 0.699294 6.28653C1.63365 1.45626 6.07038 0.000244141 10.0429 0.000244141C12.0761 0.000244141 14.7323 0.531806 16.3366 2.0456C18.3698 3.91184 18.1759 6.40209 18.1759 9.1119V15.5137C18.1759 17.4378 18.9869 18.2813 19.7508 19.3213C20.0211 19.6911 20.0799 20.136 19.739 20.4133C18.8869 21.1125 17.3708 22.4125 16.5364 23.1405L16.5246 23.1289"
            fill="white"
          />
        </g>
        <defs>
          <clipPath id="clip0">
            <rect width="135" height="40" fill="white" />
          </clipPath>
        </defs>
      </svg>
    )
  }
}
