import React, { Component } from "react"
import { Link } from "react-router-dom"
import Slider from "react-slick"
import "slick-carousel/slick/slick-theme.css"
import "slick-carousel/slick/slick.css"
import * as Api from "../../utils/Api"


// import SliderLoading from "../../assets/img/Featured-Playlist-Slider.jpg"
// import DeepHero from "../../assets/img/Deep-hero.jpg"

const settings = {
  dots: false,
  arrows: true,
  // nextArrow: <span className="arrow-left" />,
  // prevArrow: <span className="arrow-right" />,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,

  responsive: [
    {
      breakpoint: 767,
      settings: {
        arrows: false,
        dots: true,
      },
    },
  ],
}

export default class Banner extends Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: true,
      banners: [],
    }
  }

  componentDidMount() {
    Api.getHeros().then(({ data: { data } }) => {
      if (data.heros.length < 1) {
        this.setState({
          loading: false,
        })

        return
      }

      this.setState({
        banners: data.heros,
        loading: false,
      })
    })
  }

  render() {
    return (
      <div className="banner">
        <div className="wrapper">
          <div className="p-relative full">
            <Slider {...settings} className="Headerslider">
              {this.state.banners.map((banner) => {
                const inner = (
                  <div className="max-width">
                    <div className="caption">
                      <h1 className="h1">{banner.heading}</h1>
                      <div className="p">
                        <p>{banner.description}</p>
                      </div>
                      <br />

                      {banner.button_link && banner.button_text && (
                        <Link className="button" to={banner.button_link}>
                          {banner.button_text} <i className="fa fa-play-circle-o" />
                        </Link>
                      )}
                    </div>
                  </div>
                )

                return (
                  <div className="item" key={String(banner.id)}>
                    {banner.button_link ? (
                      <a
                        href={banner.button_link}
                        target="_blank"
                        rel="noreferrer noopener"
                        className="img"
                        style={{
                          backgroundImage: "url(" + banner.image + ")",
                        }}
                      >
                        {inner}
                      </a>
                    ) : (
                      <div
                        className="img"
                        style={{
                          backgroundImage: "url(" + banner.image + ")",
                        }}
                      >
                        {inner}
                      </div>
                    )}
                  </div>
                )
              })}
            </Slider>
          </div>
        </div>
      </div>
    )
  }
}
