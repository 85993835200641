import { Playlist } from "../types/playlist"

/* eslint-disable no-underscore-dangle */
export class SongInfo {
  songTitle: string | null = null
  songArtist: string | null = null
  duration = "00:00"
  artworkImageUrl: string | null = null
  uri: string | null = null

  constructor(
    songTitle = "",
    songArtist = "",
    artworkImageUrl: string | null = null,
    duration = "00:00",
    uri = "",
  ) {
    this.songArtist = songArtist
    this.songTitle = songTitle
    this.artworkImageUrl = artworkImageUrl
    this.duration = duration
    this.uri = uri
  }
}

export const Status = {
  PLAYING: "playing",
  PAUSED: "paused",
  STOPPED: "stopped",
  LOADING: "loading",
} as const

// This acts as the base interfce for the
// music player providers, this is the interface
// that the rest of the application is familiar with.
export default abstract class Provider {
  public abstract providerName: string

  abstract play(): Promise<unknown>
  abstract pause(): Promise<unknown> | void
  abstract stop(): unknown
  abstract next(): Promise<unknown>
  abstract previous(): Promise<unknown>
  abstract seek(time: number): Promise<unknown>
  abstract setPlaylist(playlist: Playlist): Promise<unknown>
  abstract setSong(mediaId: unknown): Promise<unknown>
  abstract info(): Promise<SongInfo | null>
  abstract onSongChange(handler: (...args: unknown[]) => unknown): unknown
  abstract onError(handler: (...args: unknown[]) => unknown): void
  abstract onPlayerStatusChange(handler: (...args: unknown[]) => unknown): void
  abstract onSongProgressChange(handler: (...args: unknown[]) => unknown): void
  abstract dispose(): void

  _warning(functionName = "unkown function") {
    console.warn(
      'WARNING! Function "' + functionName + '" is not overridden in ' + this.constructor.name,
    )
  }
}
