import Provider from "../providers/Provider"
import AppleHelper from "./AppleHelper"
import SpotifyHelper from "./SpotifyHelper"
import YoutubeHelper from "./YoutubeHelper"

let appleHelper: AppleHelper | null = null
let spotifyHelper: SpotifyHelper | null = null
let youtubeHelper: YoutubeHelper | null = null

type ServicesCollection = {
  youtube: YoutubeHelper | null
  spotify: SpotifyHelper | null
  apple: AppleHelper | null
}
export default class Services {
  services: ServicesCollection = {
    youtube: null,
    spotify: null,
    apple: null,
  } as const

  constructor() {
    if (appleHelper !== null) {
      this.services.apple = appleHelper
      this.services.spotify = spotifyHelper
      this.services.youtube = youtubeHelper

      return
    }

    this.services.apple = appleHelper = new AppleHelper()
    this.services.spotify = spotifyHelper = new SpotifyHelper()
    this.services.youtube = youtubeHelper = new YoutubeHelper()
  }

  checkServicesForAuthorization() {
    let provider: Provider | Promise<Provider | undefined> | null = null

    Object.entries(this.services).some(([_key, service]) => {
      switch (true) {
        case service instanceof YoutubeHelper:
          if (service?.isAuthorized()) provider = service.getProvider()
          return true
        case service instanceof AppleHelper:
          if (service?.isAuthorized()) provider = service.getProvider()
          return true
        case service instanceof SpotifyHelper:
          if (service?.isAuthorized()) provider = service.getProvider()
          return true
        default:
          throw new Error("No instance matched")
      }
    })

    return provider
  }

  getService(selector: keyof typeof this.services) {
    return this.services[selector]
  }

  getAppleService() {
    return this.services.apple
  }

  getSpotifyService() {
    return this.services.spotify
  }

  getYoutubeService() {
    return this.services.youtube
  }

  getPreAuthenticatedProvider() {
    return this.checkServicesForAuthorization()
  }
}
