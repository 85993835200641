import axios from "axios"
import { DoLogin } from "../types/do-login"
import { GetShows } from "../types/get-shows"
import { GetSongs, Song } from "./../types/get-songs"

// import { AppContext } from './Context'
// axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('authToken');

const instance = axios.create({
  withCredentials: true,
  baseURL: process.env.REACT_APP_SITE_URL + "api/deep",
})

export function doLogin(email: string, password: string, persistLogin: boolean): Promise<DoLogin> {
  return instance.post("/login", {
    email,
    password,
    persistLogin,
  })
}

export function getMe() {
  return instance.get("/me")
}

export function getSongs(): Promise<GetSongs> {
  // Switched to using cached
  // songs list

  return instance({
    url: "/songs.json",
    baseURL: "",
  })
}

export function getDspSong(dspSongId: string) {
  return instance.get(`/songs/${dspSongId}`)
}

export function getShows(): Promise<GetShows> {
  return instance.get("/shows")
}

export function getShowSetlist(showId: string) {
  return instance.get(`/shows/${showId}/setlist`)
}

export function getShowGallery(showId: string) {
  return instance.get(`/shows/${showId}/gallery`)
}

export function getHeros() {
  return instance.get("/heros")
}

export function getFeaturedPlaylists() {
  return instance.get("/featured-playlists")
}

export function getFeaturedPlaylist(id: string) {
  return instance.get(`/featured-playlists/${id}`)
}

export function getMyShows() {
  return instance.get("/my-shows")
}

export function getMyShowStats() {
  return instance.get("/my-shows/stats")
}

export function removeFromMyShows(showId: string) {
  return instance.delete(`/my-shows/${showId}`)
}

export function addToMyShows(showId: string) {
  return instance.post("/my-shows", {
    show_id: showId,
  })
}

export function getMyPlaylists() {
  return instance.get("/my-playlists")
}

export function getMyPlaylist(playlistId: string) {
  return instance.get(`/my-playlists/${playlistId}`)
}

export function createPlaylist(name: string, dsp: string, dspToken: string) {
  return instance.post(`/my-playlists`, {
    name,
    dsp,
    dsp_token: dspToken,
  })
}

export function addSongsToPlaylist(id: string, dsp: string, dspToken: string, songs: Song[]) {
  return instance.post(`/my-playlists/${id}`, {
    dsp,
    dsp_token: dspToken,
    songs: songs.map((song) => song.id),
  })
}

export function deletePlaylist(playlistId: string) {
  return instance.delete(`/my-playlists/${playlistId}`)
}

export function getAppleMusicToken() {
  return instance.get("/token/apple-music")
}

export function rateShow(showId: string, rating: boolean) {
  return instance.post(`/rate-show`, {
    show_id: showId,
    rating,
  })
}

export function rateShowSong(showId: string, songId: string, rating: boolean) {
  return instance.post(`/rate-show-song`, {
    show_id: showId,
    song_id: songId,
    rating,
  })
}
