import React, { Component } from "react"

export default class DeleteIcon extends Component {
  render() {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="22"
        height="22"
        viewBox="0 0 22 22"
        fill={this.props.fill ? this.props.fill : "#DEB421"}
      >
        <path
          d="M13.8058 6.66663L11 9.47246L8.19416 6.66663L6.66666 8.19413L9.47249 11L6.66666 13.8058L8.19416 15.3333L11 12.5275L13.8058 15.3333L15.3333 13.8058L12.5275 11L15.3333 8.19413L13.8058 6.66663ZM11 0.166626C5.00916 0.166626 0.166656 5.00913 0.166656 11C0.166656 16.9908 5.00916 21.8333 11 21.8333C16.9908 21.8333 21.8333 16.9908 21.8333 11C21.8333 5.00913 16.9908 0.166626 11 0.166626ZM11 19.6666C6.22249 19.6666 2.33332 15.7775 2.33332 11C2.33332 6.22246 6.22249 2.33329 11 2.33329C15.7775 2.33329 19.6667 6.22246 19.6667 11C19.6667 15.7775 15.7775 19.6666 11 19.6666Z"
          fill={this.props.fill ? this.props.fill : "#DEB421"}
        />
      </svg>
    )
  }
}
